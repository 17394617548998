import React from 'react';
import { message, Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getEmployeeBase64StringFromInt, getIntFromString, withMarkdownFieldValue } from '../../look';

import ROUTE from '../route';
import AddOrganizationView from '../components/AddOrganizationView';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/OrganizationFormComponent';

import { withAddOrganization, withOrganizationState } from './OrganizationOperation';
import { withAddUserToOrg } from 'modules/user/containers/UserOperations';

const AddOrganization = props => {
  const { history, createOrganization, createMember } = props;
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    const { employees, ...rest } = values;

    try {
      setLoading(true);
      const response = await createOrganization({
        employees: employees,
        organizationData: {
          ...withMarkdownFieldValue(CKEditorFieldName, withMarkdownFieldValue(CKEditorFieldName1, rest))
        }
      });
      if (response?.id){
        let memberData = {
          reportingLine: "solid",
          memberFrom: {
            organizationId: response?.id
          }
        }
        if(values?.ceoId){
           createMember({ memberData: {...memberData,employeeId: getEmployeeBase64StringFromInt(values?.ceoId)} })
        } 
 
        if(values?.poc?.length){
          values.poc.forEach(poc_id => {
            createMember({ memberData: {...memberData,employeeId: getEmployeeBase64StringFromInt(poc_id)} })
          });
          
        }
 
        ButtonsCatch(
          item,
          ROUTE.organization,
          history,
          item === undefined ? `${ROUTE.editOrganizationLink}${getIntFromString(response.id)}` : window.location.pathname
        );
      }else{
        message.error("Failed to add organization")
      }
      
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e.message)
      // throw new Error(e.message);
    }
  };

  return (
    <Spin spinning={loading} size="large">
      <AddOrganizationView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withOrganizationState, withAddOrganization, withAddUserToOrg)(AddOrganization);
