import { UPDATE_AUTH_MUTATION } from "../graphql/update-auth.gql";
import { graphql } from '@apollo/client/react/hoc';
import { message } from "antd";

export const withUpdateAccount = Component =>
    graphql(UPDATE_AUTH_MUTATION, {
        props: ({ mutate, history }) => ({
            updateAccount: async values => {
                try {
                    const {
                        data: { updateAccount }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    return updateAccount.success;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);