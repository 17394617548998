import React from 'react';
import { Row, Spin } from 'antd';
import { compose, removeItem } from '../../core';
import ROUTE from '../route';
import USER_ROUTE from '../../user/route';
import { getIntFromString, getSurveyBase64StringFromInt, getSurveyCache, removeSurveyCache } from '../../look';
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateResponseOptimized } from './SurveyOperations';
import SurveyTermsAndCondition from '../components/SurveyTermsAndCondition';
import { SURVEY_PERMISSION_CHECK } from 'modules/tool/graphql/SurveyPermission.gql';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { ORG_SURVEY_BY_ID_OPTIMIZED } from '../graphql/OrgSurveyOptimized.gql';
import { THREE_C_IDENTIFIER, WHOLE_IDENTITY_ASSESSMENT_IDENTIFIER, SURVEY_WHOLE_IDENTITY, SURVEY_ID_3CS, USER_MANUAL_SURVEY_ID, USER_MANUAL_SURVEY_IDENTIFIER, SURVEY_WHOPOSHORO, WHOPOSHORO_ASSESSMENT_SURVEY_IDENTIFIER } from '../../../config';
import GenericQuizView from 'modules/survey-look/containers/generic-quiz-view';
import { ORGANIZATION_EMPLOYEE_LIST } from '../graphql/AllEmployees.gql';


const SurveyQuiz = props => {
  const { error, history, createResponse, SurveyById, client, me, permissionLoader } = props;
  const startTime = new Date().getTime();
  const [prevTotalDuration, setPrevTotalDuration] = React.useState(0);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [surveyPermitted, setSurveyPermitted] = React.useState(false)
  const [showTermsAndCondition, setshowTermsAndCondition] = React.useState();
  const [orgSurveyById, setOrgSurveyById] = React.useState()
  const [permissionLoading, setPermissionLoading] = React.useState(true)
  const [surveyLoading, setSurveyLoading] = React.useState(true)
  const [org_employee_list, SetOrgEmployeeList] = React.useState([])
  if (error) {
    if (error.message === 'GraphQL error: Permission Denied.') {
      // setRedirect(true);
      setTimeout(3000, history.push(USER_ROUTE.login));
    }
  }

  const handleSubmit = async answers => {
    setSubmitLoading(true);
    const endTime = new Date().getTime();
    try {
      const response = await createResponse({
        answers,
        surveyId:
          (orgSurveyById && getIntFromString(orgSurveyById.id)) ||
          (SurveyById && getIntFromString(SurveyById.id)),
        totalDuration: prevTotalDuration + (endTime - startTime) / 1000 // returns time in seconds
      });
      response && setSubmitted(true);
      response && removeItem(orgSurveyById ? orgSurveyById.id : SurveyById.id);
      response && removeSurveyCache(getIntFromString(orgSurveyById?.id))
      if (response) history.push(`${ROUTE.surveyResponseLink}${getIntFromString(response.id)}`);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
    
  };

  React.useEffect(() => {
    if (me) {
      getSurveyPermission()
    }
  }, [me])

  React.useEffect(() => {
    if (props.match.params.id == SURVEY_ID_3CS) {
      orgEmployeeList({})
    }
  }, [props.match.params.id])

  const orgEmployeeList = async (filter) => {
    try {
      const { data } = await client.query({
        query: ORGANIZATION_EMPLOYEE_LIST,
        variables: { ...filter },
        fetchPolicy: 'network-only'
      });   
      if (data) {
        let employee_with_firstname = data?.first_name?.edges?.map(({ node }) => { return { ...node?.user, employee_id: node?.id } })
        let employee_with_lastname = data?.last_name?.edges?.map(({ node }) => { return { ...node?.user, employee_id: node?.id } })
        let employee_with_email = data?.email?.edges?.map(({ node }) => { return { ...node?.user, employee_id: node?.id } })
        
        let list = [...employee_with_firstname, ...employee_with_lastname, ...employee_with_email]
        let setObj = new Set(); // create key value pair from array of array
        let result = list.reduce((acc, item) => {
          if (!setObj.has(item.employee_id)) {
            setObj.add(item.employee_id)
            acc.push(item)
          }
          return acc;
        }, [])?.sort((a, b) => a?.firstName?.toLowerCase()?.localeCompare(b?.firstName?.toLowerCase()))
        SetOrgEmployeeList(result)
      }
    } catch (e) {

    }
    setPermissionLoading(false)
  }

  // React.useEffect(() => {
  //   if (orgSurveyById?.id && orgSurveyById?.termsConditions && surveyPermitted) {
  //     let { data } = getSurveyCache(getIntFromString(orgSurveyById?.id)) 
  //     if(!data?.length){
  //       setshowTermsAndCondition(true)
  //     }
      
  //   }

  // }, [orgSurveyById, surveyPermitted])


  const getSurveyPermission = async () => {

    let identifier;
    if (parseInt(props.match.params.id) === SURVEY_ID_3CS) {
      identifier = THREE_C_IDENTIFIER
    } else if (parseInt(props.match.params.id) === SURVEY_WHOLE_IDENTITY) {
      identifier = WHOLE_IDENTITY_ASSESSMENT_IDENTIFIER
    }
    else if (parseInt(props.match.params.id) === USER_MANUAL_SURVEY_ID) {
      identifier = USER_MANUAL_SURVEY_IDENTIFIER
    }
    else if (parseInt(props.match.params.id) === SURVEY_WHOPOSHORO) {
      identifier = WHOPOSHORO_ASSESSMENT_SURVEY_IDENTIFIER
    }

    setPermissionLoading(true)
    try {
      const { data } = await client.query({
        query: SURVEY_PERMISSION_CHECK,
        variables: { identifier: identifier },
        fetchPolicy: 'network-only'
      });

      if (data?.allSurveys) {
        let count = data?.allSurveys?.totalCount;

        if (count > 0) {
          setSurveyPermitted(true)
        }
      }
    } catch (e) {
      console.error(e)
    }
    setPermissionLoading(false)
  }

  React.useEffect(() => {
    if (surveyPermitted) {
      getOrgSurveyById()
    }
  }, [surveyPermitted])



  const getOrgSurveyById = async () => {
    setSurveyLoading(true)
    let id = '0';
    if (props.match) {
      id = props.match.params.id;
    } else if (props.navigation) {
      id = props.navigation.state.params.id;
    }
    try {
      const { data } = await client.query({
        query: ORG_SURVEY_BY_ID_OPTIMIZED,
        variables: { id: getSurveyBase64StringFromInt(Number(id)) },
        fetchPolicy: 'network-only'
      });

      if (data?.orgSurveyById) {
        setOrgSurveyById(data?.orgSurveyById)
      }
      setSurveyLoading(false)
    } catch (e) {
      console.error(e)
      setSurveyLoading(false)
    }

  }

  return (
    <div>
      {
        !permissionLoading &&

        <div>
          {
            !surveyPermitted || (orgSurveyById && orgSurveyById == null) && !surveyLoading ?
              <NoPermissionView content={"You Don't Have Permission To View This Survey."} /> :
              <Spin spinning={submitLoading} size="large">
                {
                  showTermsAndCondition && orgSurveyById?.termsConditions && (
                    <SurveyTermsAndCondition
                      termsAndConditon={orgSurveyById?.termsConditions}
                      accept={() => { setshowTermsAndCondition(false) }}
                      back={() => { history.goBack() }}
                    />
                  )
                }

                <GenericQuizView
                  submitted={submitted}
                  onSubmit={handleSubmit}
                  orgSurveyById={orgSurveyById}
                  setPrevTotalDuration={setPrevTotalDuration}
                  quizStartTime={startTime}
                  showTermsAndCondition={showTermsAndCondition}
                  org_employee_list={org_employee_list}
                  orgEmployeeList={orgEmployeeList}
                  setshowTermsAndCondition={setshowTermsAndCondition}
                  surveyPermitted={surveyPermitted}
                  loading={surveyLoading}
                  {...props}
                />
              </Spin>
          }

        </div>

      }
    </div>


  );
};

export default compose(withCreateResponseOptimized, withApollo)(SurveyQuiz);