import React from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  Carousel,
  Card,
  Progress,
  Skeleton,
  Row,
  Button,
  Col,
  Form,
  Empty,
  Modal,
  Tooltip,
  Space,
  Spin
} from "antd";
import { MetaTags, getIntFromString, removeSurveyCache } from "../../look";
import ReactMarkdown from "../../look/components/ReactMarkdown";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import SoulSurveySuccessPopup from "./SoulSurveySuccessPopup";
import mapp_learning_path_routes from "modules/learning-path/routes";
import { VALUE_SURVEY_ID } from "config";
const SoulSurveySubmissionView = (props) => {
  const {
    onSubmit,
    SurveyById,
    setPrevTotalDuration,
    quizStartTime,
    history,
    submitted,
    data,
    createResponse,
    soul_survey_completion,
    app_sidebar_expand,
    navigateRoute
  } = props;
  const [loading, setloading] = React.useState([props?.loading]);
  const [success_popup, SetSuccessPopup] = React.useState(false)
  const formValues = props?.data?.formValues?.submitValues;
  const soulValues = props?.data?.selected?.values;

  const sortData = props?.data?.submissionData.sort(function (a, b) {
    return b.node.submitted?.percent - a.node.submitted?.percent;
  });
  const [submissionData, setSubmissionData] = React.useState([]);
  const [responseId, setResponseId] = React.useState()

  React.useEffect(() => {
    setSubmissionData(sortData)
  }, [sortData])

  React.useEffect(() => {

    if (submissionData && submissionData?.length) {
      setloading(false)
    }

  }, [submissionData])

  async function handleSubmit() {
    try {
      setloading(true)
      let answerList = []

      let valueList = SurveyById.groupSet.edges.find(val => val.node.name === 'Top 10 values')

      submissionData.forEach((subdata, index) => {

        let data = {
          questionId: valueList?.node?.questionSet?.edges[index]?.node?.id,
          answer: subdata?.node?.name,
          responseDuration: Math.floor(subdata?.node?.submitted?.responseTime),
          sequence: Math.round(subdata?.node?.submitted?.percent)
        }
        answerList.push(data)
      })

      submissionData.forEach((data) => {
        data.node.questionSet.edges.forEach(quset => {
          let set = formValues.find(sub => sub.questionId === quset.node.id)

          if (set) {
            set.responseDuration = Math.floor(set.responseDuration)
            answerList.push(set)
          }
        })
      })

      answerList.map(survey => survey.questionId = getIntFromString(survey.questionId))

      let totalDuration = Math.floor(formValues.reduce((a, b) => a + b?.responseDuration, 0))

      const response = await createResponse({ answers: answerList, surveyId: 3, totalDuration: totalDuration });
      if (response && response?.id) {
        setloading(false)
        localStorage.removeItem("selectedSoulValue")
        localStorage.removeItem("formValues")
        removeSurveyCache(VALUE_SURVEY_ID)
        setResponseId(response?.id)
        let status =localStorage.getItem('to_leraning')
        if(status =='value'){
          localStorage.setItem('to_leraning', 'value_completed')
          navigateRoute(mapp_learning_path_routes?.soul_learning_path)
        }
        else{
          if (soul_survey_completion && soul_survey_completion?.Strength?.totalCount === 0) {
            SetSuccessPopup(true)
          } else {
            history.push(`/soul/values`)
          }
        }
      }
    } catch (err) {
      setloading(false)
    }
  }
  const SortableItem = SortableElement(({ value }) => <Column>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em" }}>


      <CardView style={{ width: "300px", background: value?.index < 5 ? '#4CCB1F' : 'transparent', borderColor: value?.index < 5 ? '#4CCB1F' : '#CACACA', borderRadius: ".5em" }} >
        <Row align="center">
          <h3 style={{ fontWeight: "normal", color: value?.index < 5 ? "#fff" : "#000", fontFamily: "Poppins" }}>
            <ReactMarkdown>{value?.node?.label}</ReactMarkdown>
          </h3>
          <h3 style={{ fontWeight: "normal", marginLeft: "5px", color: value?.index < 5 ? "#fff" : "#000", fontFamily: "Poppins" }}>
            - {value?.node?.submitted?.percent}%
          </h3>
        </Row>
      </CardView>
      <div className="menu-icon" style={{ marginTop: "-1.25em", cursor: "grab" }}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  </Column>);

  const SortableList = SortableContainer(({ items }) => {
    items.forEach((data, index) => data['index'] = index)

    return (
      <ul>
        {submissionData.map((value, index) => (
          <SortableItem key={`item-${value?.node?.label}`} index={index} value={value} />
        ))}
      </ul>
    );
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {

    // let items = submissionData[oldIndex]
    // let x = submissionData.slice(0)
    // x.splice(oldIndex,1)
    // x.splice(newIndex, 0, items);
    const newData = arrayMove([].concat(submissionData), oldIndex, newIndex)
    setSubmissionData(newData)
  };

  return (
    <>
      <Modal
        visible={success_popup}
        footer={false}
        closable={false}
        destroyOnClose={true}
        centered={true}
        style={{ maxWidth: "45em" }}
        className='idp-modal'
        width={"90%"}
      >
        <SoulSurveySuccessPopup
          message="Do you want to go to strength?"
          main_warning={`You have successfully submitted your response.`}
          onCancel={() => { { SetSuccessPopup(false); history.push(`/soul/values`) } }}
          onConfirm={() => { { SetSuccessPopup(false); history.push(`/soul/strengths`) } }}
        />
      </Modal>

      <Spin spinning={loading}>
        <MetaTags
          title="soul sourvey submission"
          description="This is soul sourvey submission page."
        />
        {
          submissionData.length ? <Col>
            {/* <Col>
           <h4>Progress</h4>
           <Progress
             percent={Math.round(100)}
             strokeColor={{
               "0%": "#108ee9",
               "100%": "#87d068",
             }}
             style={{
               display: "block",
             }}
             showInfo={false}
             status="active"
           />
         </Col> */}
            <h3
              style={{ fontWeight: "500" }}
            >
              <ReactMarkdown>
                Here are your top 5 values based on the scores you got per the
                survey you just took. If you agree with these, click Submit and we
                will plug them in as your top 5 values, or if you would like to make
                a change, drag and drop values to change the order and then click
                Submit.
              </ReactMarkdown>
            </h3>
          </Col> : ''
        }



        <Col style={{ marginTop: "50px" }}>
          <div style={{ paddingBottom: "5em" }}>
            <SortableList items={submissionData} onSortEnd={onSortEnd} />
          </div>


          {submissionData.length ? <div className={`one-on-one-bottom-div ${app_sidebar_expand ? 'survey-section-container-margin-left' : ''}`}
            style={{ padding: "0px", paddingBottom: "10px", paddingRight: "0px" }}>
            <div style={{ width: '100%' }} className={!app_sidebar_expand ? 'custom-alignment' : ''} align="center">
              <Button className='save-step-2-button' type="primary" style={{ marginTop: '1em' }} onClick={handleSubmit}>
                Submit
              </Button>

            </div>
          </div> : null}
          {/* <Col span={24} align="right">
            {submissionData.length ?
              <div style={{ paddingBottom: "70px" }} >
                
              </div>
              : ''}


          </Col> */}


        </Col>
      </Spin>
    </>
  );
};
export default SoulSurveySubmissionView;
const CardView = styled(Card)`
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;
  border: 2px solid #cacaca;
`;
const Column = styled(Col)`
  display: flex;
  justify-content: center;
`;
