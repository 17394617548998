import mapp_learning_path_routes from '../routes'
import page_route from 'modules/page/route'
import { soul_type } from 'modules/page/containers/homePageContent'
import diversity_survey_route from 'modules/diversity-survey/route'
import { SURVEY_DIVERSITY_PROFILE, VALUE_SURVEY_ID } from '../../../config'
export const learningConfig = {
    dashboard_landing_content:"Embark on a journey of knowledge and skill development with our carefully curated learning paths.Each path is designed to take you step-by-step through a series of modules, ensuring a seamless and comprehensive learning experience.",

    // card content
    card_details:[
        {
            card_title:"Soul",
            time:'1.5Hr',
            description:"Soul is meant for you to explore your individual identity. The Soul is a structured exploration to identify your values, strengths, personality orientation and your knowledge and skills and explore how these impact your decision making, communication, collaboration and relationship building. At the end of it, the you can generate a crisp Impact Narrative, a snapshot of how you prefer to work. You can use your and your team’s Impact Narratives to custpersonalize your conversations with your team members and engage them for high performance and help team members bring their true selves at work. WIth the Impact Narrative, you can also be more intentional about your leadership style and find your flex.",
            background: "linear-gradient(181.28deg, #FFFFFF 42.64%, #BFD1FF 98.91%)",
            boxshadow: "0px 10px 15px 0px #5674FA4A",
            icon:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739514622/MApp/UI/Images/learn-soul-icon_yfyxca.png",
            route:mapp_learning_path_routes.soul_learning_home
        },
        {
            card_title:"Role",
            time:'1.5Hr',
            description:"This is a rich toolkit that allows managers to manage the ecosystem of various roles and their goals through 1:1s, Feedback, OKRs, Coaching, Competency Readiness Assessment, Individual Development Plans, and Managing Performance.",
            background: "linear-gradient(181.28deg, #FFFFFF 42.64%, #D8F3FF 98.91%)",
            boxshadow: "0px 10px 15px 0px #A3DBF34F",
            icon:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739515399/MApp/UI/Images/learn-role-icon_p88uru.png"
        },
        {
            card_title:"Shoal",
            time:'1.5Hr',
            description:"Shoal helps you explore team's identity by letting you explore insights from the team to find strategies to raise the bar for the team. With Shoal you can assess trust levels for the team and plan for building or rebuilding trust along with getting to know how to work with people on a deeper and a personal level.  The Shoal will help you get a team view from a diversity perspective as well.",
            background: "linear-gradient(181.28deg, #FFFFFF 42.64%, #D6E6FF 98.91%)",
            boxshadow: "0px 10px 15px 0px #3C6CD04",
            icon:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739515401/MApp/UI/Images/learn-shole-icon_jewyn8.png"
        },
        {
            card_title:"Whole",
            time:'1.5Hr',
            description:"Whole is the bigger teams and organizations we are part of. It helps you get a pulse check on how the team is doing, where the leadership style is working and not, and what should you consider doing differently. Understanding how the company identity impacts the identity of the employee is important and useful for managers. It helps to know which factor of the Whole identity finds strong resonance with an employee and which seems to have a disconnect at a point in time can be good advice for managers to engage the employee better.",
            background: "linear-gradient(181.28deg, #FFFFFF 42.64%, #E5F9FF 98.91%)",
            boxshadow: "0px 10px 15px 0px #8DCCE847",
            icon:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739515406/MApp/UI/Images/learn-whole-icon_zs9dtc.png"
        },
        {
            card_title:"Survey",
            time:'1.5Hr',
            description:"Explore your leadership style through self and 360 feedback to gain insights and increase the impact of your leadership.",
            background: "linear-gradient(181.28deg, #FFFFFF 42.64%, #E2D4FF 98.91%)",
            boxshadow: "0px 10px 15px 0px #8856ED3D",
            icon:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739515403/MApp/UI/Images/learn-survey-icon_dcpfkq.png"
        },
        {
            card_title:"Kudos",
            time:'1.5Hr',
            description:"Instantly recognise and celebrate wins by giving Kudos to your team members.",
            background: "linear-gradient(181.28deg, #FFFFFF 42.64%, #FFF5D4 98.91%)",
            boxshadow: "0px 10px 15px 0px #F1E3B478",
            icon:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739515397/MApp/UI/Images/learn-kudos-icon_z7ecai.png"
        }
    ],

    // Soul Learning contents
    soul_baner_content:"Soul is meant for you to explore your individual identity. The Soul is a structured exploration to identify your values, strengths, personality orientation and your knowledge and skills and explore how these impact your decision making, communication, collaboration and relationship building. At the end of it, the you can generate a crisp Impact Narrative, a snapshot of how you prefer to work. You can use your and your team’s Impact Narratives to custpersonalize your conversations with your team members and engage them for high performance and help team members bring their true selves at work. WIth the Impact Narrative, you can also be more intentional about your leadership style and find your flex. ",
    soul_banner_video_url: "https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1726817119/MApp/UI/Videos/Soul/Mapp_Soul_Identity_Module.mp4",
    soul_card_detail:[
        {
            key:"value",
            title:"Values",
            timer:"20",
            no_of_questions:"30",
            thumbnail: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739523020/MApp/UI/Images/lp_values_thumbnail_usczwv.png",
            video_url:"https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1737281062/MApp/UI/Videos/Values_Exploration_tsqfaw.mp4",
            path:`/soul/values/survey/${VALUE_SURVEY_ID}`,
            info:"<p>Values are our personal guiding principles. Our personal values decide what we value and don't. Therefore, they also influence what we think is right or wrong. Our sense of integrity is based on them. Aligning with values will allow us a space where we feel we are doing the right things the right way. Where values are being compromised, we won't be at peace. We may feel disrespected at one end and compromised at the other. Therefore, it's important for us to know our values and be purposeful about them.</p>"
            
        },
        {
            key:"strengths",
            title:"Strengths",
            timer:"20",
            no_of_questions:"120",
            thumbnail:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739523017/MApp/UI/Images/lp_strength_thumbnail_oxynag.png",
            video_url:"https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1651241521/MApp/UI/Videos/Strengths_osuywt.mp4",
            path: page_route.home?.replace(":type",soul_type.strengths),
            info:"<p>Strengths are natural gifts we have that help us do some activities better than others without a lot of effort and, at the same time, be energized by the experience. Given these are natural gifts, if we are able to discover and hone them, we can create outsized impact. There's deep research in positive psychology on strengths and how to discover and grow them so we can be more successful.</p>"
            
        },
        {
            key:"personality",
            title:"Personalities",
            timer:"20",
            no_of_questions:"60",
            thumbnail:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739523014/MApp/UI/Images/lp_personalities_thumbnail_auclzl.png",
            video_url:"https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1651238267/MApp/UI/Videos/Personality_Orientations_ojqowb.mp4",
            path: page_route.home?.replace(":type",soul_type.personalities),
            info:"<p>Where do we get our energy from? How do we take in information? How do we make decisions? How do we get things done? Dr Carl Jung's pioneering research into personality demonstrates that we have our preferred ways, and being aware of these preferences helps us know how we choose to behave and why. Awareness of our personality preferences helps us understand our unique style of showing up and determine whether it's serving us well or not. We can then step out of our comfort zone to achieve better results. Personality awareness helps us build trust, better relationships, be more effective and collaborate smoothly.</p>"
            
        },
        {
            key:"knowledge_skills",
            title:"Knowledge & Skills ",
            timer:"20",
            no_of_questions:"5",
            thumbnail:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739523013/MApp/UI/Images/lp_knowledge_thumbnail_mr0dyv.png",
            video_url:"https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1651237552/MApp/UI/Videos/Knowledge_Skills_2_do3tvp.mp4",
            path: page_route.home?.replace(":type",soul_type.knowledge_and_skills),
            info:"<p>Our education, the school we went to, the training we received, who our teachers and peers were, all of these contribute to shaping our sense of identity. We also gather knowledge through informal channels and from our experience that we are able to use to create visible impact at work and otherwise. This sense of identity also evolves as we become increasingly aware of the skills we have and our levels of expertise. Think about how these factors impact your sense of identity and enter top 5 knowledge areas/skills that contribute the most to your identity.</p>"
            
        },
        {
            key:"impact_narrative",
            title:"Impact Narrative ",
            timer:"1",
            thumbnail:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739523010/MApp/UI/Images/lp_impact_thumbnail_wnhz7y.png",
            video_url:"https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1726817226/MApp/UI/Videos/Soul/Mapp_-_Impact_Narrative_Module.mp4",
            path: page_route.home?.replace(":type",soul_type.impact_narrative),
            info:"<p>An Impact Narrative provides a comprehensive snapshot of an individual's work style, identifying key drivers of performance, including demographics, personal values, personality preferences, and strengths. This powerful tool enables people leaders to personalize their leadership style to each team member's unique preferences, fostering self-awareness and empowering team members to make intentional contributions. Additionally, Impact Narratives can be compared across teams, enhancing cross-functional collaboration and promoting inclusivity within the organization. By leveraging Impact Narratives, leaders can optimize their approach to meet the diverse needs of their team, driving more effective collaboration and better outcomes.<br/>Impact Narratives is a benefit for team members as well, as it fosters deeper self-awareness enabling them to discover their areas of strengths and development. understanding colleagues' work styles and preferences through Impact Narratives enhances collaboration and builds stronger working relationships. This leads to improved engagement, motivation, and job satisfaction, as individual strengths are recognized and leveraged. </p>"
            
        },
        {   
            key:"diversity_profile",
            title:"Diversity Profile",
            timer:"10",
            no_of_questions:"30",
            thumbnail: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739522844/MApp/UI/Images/lp_diversity_thumbnail_yozmj1.png",
            video_url: "https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1651241823/MApp/UI/Videos/Why_Diversity_Profile_vtj3dk.mp4",
            path:diversity_survey_route?.diversityHome?.replace(":id",SURVEY_DIVERSITY_PROFILE),
            info:'<p>With the goal of inclusive workplaces, an important first step is understanding the diversity that wwe currently have on our team and what are some gaps. The Diversity Profile asks your various common questions like your gender, age, education, experience. On top of that, it also asks you questions related to your marital status, sexual orientation, that you can choose whether to answer. As everybody on the team fills the Diversity profile, you will get a really broad view of the diversity on your team or "Diversity Scorecard" in one place that you can find in Shoal > Team Dynamics. You can start thinking about how to engage various diversities and how to fill the gaps in diversities based on the Team Dynamics.</p>'
            
        }
    ]
}