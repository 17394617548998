import gql from 'graphql-tag';

export const ORGANIZATION_SUBSCRIPTION = gql`
  subscription organizationSubscription {
    organizationSubscription {
      mutation
      organization{
        id
        name
        description
        email
        logo
        poc {
          edges{
            node{
              id
              user {
                id
                username
                firstName
                lastName
                email
              }
            }
          }
        }
        ceo {
          id
          user {
            id
            username
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
