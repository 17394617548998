import { compose } from "modules/core";
import { organization_icons } from "modules/poc-dashboard/icons";
import React from "react"
import { withApollo } from '@apollo/client/react/hoc';
import gql from "graphql-tag";
import { Input, Spin } from 'antd'
import { Capitalize } from "modules/look";

const OrganizationDetailView = (props) => {
    const { detail, onClose, selected_node, client } = props
    const [employee_loading, setEmployeeListLoading] = React.useState(false)
    const [page_info, SetPageInfo] = React.useState()
    const [employee_list, SetEmployeeList] = React.useState([])
    const [manager_list, SetManagerList] = React.useState([])
    const employee_list_ref = React.useRef([])

    React.useEffect(() => {
        employee_list_ref.current = employee_list
    }, [employee_list])

    React.useEffect(() => {
        if (selected_node && detail) {
            selectModeType(selected_node, {})
            let manager_list = detail?.manager?.map(i => {
                return {
                    emp_id: i?.empolee_id,
                    email: i?.email,
                    name: i?.name,
                    user_type: i?.role,
                    profile_image: i?.profile_image
                }
            })
            SetManagerList(manager_list)
        }
    }, [selected_node, detail])

    let search_timer = React.useRef()
    let search_ref = React.useRef()
    const searchEmployee =(search)=>{
        if(search_timer.current){
            clearTimeout(search_timer?.current)
        }
        search_timer.current = setTimeout(() => {
            search_ref.current = search
            selectModeType(selected_node, {user_FirstName:search})
        }, 500);
    }

    const selectModeType = (selected_node, filter) => {
        if (selected_node) {
            switch (selected_node?.node_type) {
                case "ORGANIZATION":
                    AllemployeeList(filter)
                    break;
                case "VERTICAL":
                    VerticalEmployee(filter)
                    break;
                case "SUB-VERTICAL":
                    VerticalEmployee(filter)
                    break;
                case "TEAM":
                    TeamEmployee(filter)
                    break;
                default:
                    break;
            }
        }
    }

    const ALL_VERTICAL_EMPLOYEE_LIST = gql`
    query vertical($id:ID!,$after:String,$user_FirstName:String){
        vertical(id:$id){
            id
            employees(after:$after,first:25,user_FirstName:$user_FirstName){
                pageInfo{
                    hasNextPage
                    endCursor
                }
                edges{
                    node{
                        id
                        orgCeoEmployee{
                            totalCount
                        }
                        orgPocEmployee{
                            totalCount
                        }
                        verticalHeadEmployee{
                            totalCount
                        }
                        teamManagerEmployee{
                            totalCount
                        }
                        user{
                            id
                            email
                            firstName
                            lastName
                            profile{
                                id
                                profileImage
                            }
                        }
                    }
                }
            }
        }
    }
    `

    const ALL_TEAM_EMPLOYEE_LIST = gql`
    query Team($id:ID!,$after:String,$user_FirstName:String){
        Team(id:$id){
            id
            employees(after:$after,first:25,user_FirstName:$user_FirstName){
                pageInfo{
                    hasNextPage
                    endCursor
                }
                edges{
                    node{
                        id
                        orgCeoEmployee{
                            totalCount
                        }
                        orgPocEmployee{
                            totalCount
                        }
                        verticalHeadEmployee{
                            totalCount
                        }
                        teamManagerEmployee{
                            totalCount
                        }
                        user{
                            id
                            email
                            firstName
                            lastName
                            profile{
                                id
                                profileImage
                            }
                        }
                    }
                }
            }
        }
    }
    `
    const AllemployeeList = async (filter) => {
        try {
            let manager_list = detail?.manager?.map(i => {
                return {
                    emp_id: i?.empolee_id,
                    email: i?.email,
                    name: i?.name,
                    user_type: i?.role,
                    profile_image: i?.profile_image
                }
            })
            let list = sortData(manager_list)
            SetEmployeeList(list)
        } catch (error) {
            setEmployeeListLoading(false)
        }
    }

    const VerticalEmployee = async (filter) => {
        try {
            setEmployeeListLoading(true)
            const { data } = await client.query({
                query: ALL_VERTICAL_EMPLOYEE_LIST,
                variables: { id: selected_node?.node_id, ...filter },
                fetchPolicy: 'network-only'
            });

            if (data?.vertical?.employees) {
                let manager_ids = detail?.manager?.map(i => i?.empolee_id)
                let list = handleEmployeeList(data?.vertical?.employees?.edges)?.filter(i => !manager_ids?.includes(i?.emp_id))
                list = sortData(list)
                if (filter?.after) {
                    list = employee_list_ref?.current.concat(list)
                }
                SetPageInfo(data?.vertical?.employees?.pageInfo)
                SetEmployeeList(list)
                setEmployeeListLoading(false)
            } else {
                setEmployeeListLoading(false)
            }
        } catch (error) {
            setEmployeeListLoading(false)
        }
    }

    const TeamEmployee = async (filter) => {
        try {
            setEmployeeListLoading(true)
            const { data } = await client.query({
                query: ALL_TEAM_EMPLOYEE_LIST,
                variables: { id: selected_node?.node_id, ...filter },
                fetchPolicy: 'network-only'
            });

            if (data?.Team?.employees) {
                let manager_ids = detail?.manager?.map(i => i?.empolee_id)
                let list = handleEmployeeList(data?.Team?.employees?.edges)?.filter(i => !manager_ids?.includes(i?.emp_id))
                list = sortData(list)
                if (filter?.after) {
                    list = employee_list_ref?.current.concat(list)
                }
                SetPageInfo(data?.Team?.employees?.pageInfo)
                SetEmployeeList(list)
                setEmployeeListLoading(false)
            } else {
                setEmployeeListLoading(false)
            }
        } catch (error) {
            setEmployeeListLoading(false)
        }
    }

    const handleEmployeeList = (list) => {
        return list?.map(({ node }) => {
            return {
                emp_id: node?.id,
                // user_id: node?.user?.id,
                email: node?.user?.email,
                name: `${Capitalize(node?.user?.firstName)} ${Capitalize(node?.user?.lastName) || ''}`,
                user_type: "Employee",
                profile_image: node?.user?.profile?.profileImage
            }
        })
    }

    const getUserType = (employee) => {
        if (employee?.orgCeoEmployee?.totalCount) {
            return "CEO"
        }
        else if (employee?.orgPocEmployee?.totalCount) {
            return "POC"
        }
        else if (employee?.verticalHeadEmployee?.totalCount) {
            return "Vertical Head"
        }
        else if (employee?.teamManagerEmployee?.totalCount) {
            return "Manager"
        } else {
            return "Employee"
        }
    }

    const user_type_detail = {
        "CEO": {
            order: 1
        },
        "POC Admin": {
            order: 2
        },
        "Vertical Head": {
            order: 3
        },
        "Manager": {
            order: 4
        },
        "Employee": {
            order: 5
        }
    }

    const sortData = (list = []) => {
        return list?.sort((a, b) => user_type_detail[a?.user_type]?.order - user_type_detail[b?.user_type]?.order)
    }

    return (

        selected_node?.node_type == "ORGANIZATION" ?
            <div className="org-modal-detail" >
                <div className="org-color-container" style={{ background: detail?.detail?.organization?.color || "rgba(178, 204, 38, 0.38)" }}>

                </div>
                <div className="org-detail-container">
                    <div className="close-icon-container">
                        <img src={organization_icons.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onClose(false)} />
                    </div>
                    <div className="org-detail-section">
                        <div className="org-logo-container" style={{ border: detail?.detail?.organization?.logo ? `2px solid ${detail?.detail?.organization?.color || "rgba(175, 226, 35, 1)"}` : "2px solid transparent" }}>
                            {
                                detail?.detail?.organization?.logo && (
                                    <img src={detail?.detail?.organization?.logo} alt="" />
                                )
                            }
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <h4 className="modal-main-title">{detail?.detail?.organization?.name}</h4>
                            <h4 className="modal-sub-title">{detail?.detail?.organization?.email}</h4>
                        </div>
                    </div>

                    <div className="popup-overflow-manager">

                        <div className="userlist-main-section">
                            {
                                manager_list?.map(i => (
                                    <div className="manager-detail-container">
                                        <div className="manager-logo-container">
                                            <img src={i?.profile_image || organization_icons?.profile_icon} alt="" />
                                        </div>
                                        <div className="org-manager-info">
                                            <h3>{i?.name}</h3>
                                            <h4 style={{ fontWeight: "500" }}>{i?.user_type}</h4>
                                            <h4>{i?.email}</h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>



                    </div>

                </div>
            </div>
            :
            <div className="org-modal-detail">
                <div className="org-detail-container">
                    <div className="org-detail-spacebtw">
                        <h4 className="modal-sub-title" style={{ fontWeight: "600", lineHeight: "normal" }}>{detail?.detail?.label}</h4>
                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                            <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                                <img src={organization_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                                <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search" onChange={(e) => searchEmployee(e?.target?.value)} />
                            </div>
                            <img src={organization_icons.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onClose(false)} />
                        </div>
                    </div>


                    <div className="popup-usersection-overflow-manager">
                        {/* manager list */}
                        <div className="userlist-main-section">
                            {
                                manager_list?.map(i => (
                                    <div className="employee-card-container">
                                        <div className="manager-logo-container">
                                            <img src={i?.profile_image || organization_icons?.profile_icon} alt="" />
                                        </div>
                                        <div className="org-manager-info">
                                            <h4 style={{ fontWeight: "500" }}>{i?.user_type}</h4>
                                            <h3 style={{ fontWeight: "500" }}>{i?.name}</h3>
                                            <h4>{i?.email}</h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        {/* employee list */}
                        <Spin spinning={employee_loading}>
                            <div className="userlist-main-section">
                                {
                                    employee_list?.map(i => (
                                        <div className="employee-card-container" style={{ borderLeft: "5px solid rgba(76, 203, 31, 0.39)", paddingLeft: "4px" }}>
                                            <div className="org-manager-info">
                                                <h4 style={{ fontSize: "1em" }}>{i?.name}</h4>
                                                <h4 style={{ fontSize: "0.9em" }}>{i?.email}</h4>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Spin>
                        {
                            (!employee_loading && employee_list?.length && page_info?.hasNextPage) ? (
                                <div style={{ width: "100%", margin: "1em" }} align="center"><h4 style={{ fontSize: "1.25em", cursor: "pointer" }} onClick={() => selectModeType(selected_node, { after: page_info?.endCursor,user_FirstName:search_ref?.current })}>View More</h4></div>
                            ) : ''
                        }
                    </div>
                </div>
            </div>

    )
}

export default compose(withApollo)(OrganizationDetailView)