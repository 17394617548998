import Scape_Tool from '../../assets/Scape_Tool.svg'
import feedback_2 from '../../assets/feedback-2.svg'
import okrs_2 from '../../assets/okrs-2.svg'
import one_on_ones from '../../assets/one_on_ones.svg'
import Readines_Tool from '../../assets/Readines_Tool.svg'
import trust from '../../assets/trust.svg'
import the_svgwhole from '../../assets/the_svgwhole.svg'
import survey from '../../assets/360_survey.svg'
import Coaching_Conversation from '../../assets/Coaching_Conversation.svg'

export const SalesPageToolSet = [
    {
        id: 1,
        tool: 'WhoPoShoro', title: 'WhoPoShoro', toolImage: survey, type: "whoposhoro",
        rowSet: [{
            id: 1, rowType: 'textFirst', heading: 'WhoPoShoro', showbutton: true, justify: 'center', imageSpan: 12, textSpan: 10, textWidth: '95%',
            text: "Host any 360 survey & generate impactful reports. 360 survey is a turbocharged evaluation tool that collects feedback from you, your manager and your direct reports. It offers a panoramic view of strengths and growth areas, fostering personal development, self-awareness, and improved teamwork. Engage  effectively and soar to new heights! The uniqueness of Mapp is the WhoPoShoRo assessment .",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449581/MApp/UI/Images/Sales/whoposhoro_compressed_pbdiru.png"
        },
        {
            id: 2, rowType: 'imageFirst', heading: 'Soul', justify: 'space-around', imageSpan: 8,
            text: "Embark on a Curious Quest: Awakening the Impact of Self-Awareness and the Soul's Diversity. Take the Soul Assessment to discover the values your soul deeply connects with.",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449579/MApp/UI/Images/Sales/soul_whoposhoro_1_rnmmb9.png"
        },
        {
            id: 3, rowType: 'textFirst', heading: 'Role', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
            text: "Discover your role, set smart goals, give balanced feedback, create inviting JDs, and excel in your all-round performance. Understand and optimise your role in the organisation in our Role Module",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449585/MApp/UI/Images/Sales/whoposhoro_role_kqjqbc.png"
        },
        {
            id: 4, rowType: 'imageFirst', heading: 'Shoal', justify: 'space-around', marginLeft: '0px', imageSpan: 8, align: 'middle', textWidth: '80%',
            text: "Build trust, ignite collaboration, harness strengths, champion inclusion, foster unity, and ensure accountability. Elevate your team for success! Buiding exceptional  teams just becomes easier with our SHOAL module.",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449588/MApp/UI/Images/Sales/whoposhoro_shoal_ief2ow.png"
        },
        {
            id: 5, rowType: 'textFirst', heading: 'Poll', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
            text: "Supercharge your leadership: coaching, empathy, inclusivity, career management, executive presence, visioning, and influence. Ignite your full potential by using the Poll module on Mapp",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449583/MApp/UI/Images/Sales/whoposhoro_poll_faufmr.png"
        },
        {
            id: 6, rowType: 'imageFirst', heading: 'Whole', justify: 'space-around', marginLeft: '0px', imageSpan: 8, align: 'middle', textWidth: '80%',
            text: "Discover the capabilities of our tool: empower integrative leadership, facilitate cross-functional collaboration, excel in stakeholder management, and foster a sense of ownership. Achieve remarkable outcomes by leveraging these features to drive teamwork, alignment, and accountability in your organization.",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449590/MApp/UI/Images/Sales/whoposhoro_whole_iewe9z.png"
        },
        ]
    },
    {
        id: 2,
        tool: 'One-On-One', title: 'One-On-One', toolImage: one_on_ones, type: "oneonone",
        rowSet: [{
            id: 1, rowType: 'textFirst', heading: 'Transform your One-on-Ones', showbutton: true, justify: 'center', imageSpan: 12, textSpan: 10, textWidth: '100%',
            text: "Say goodbye to the hassle of manually scheduling and managing one-on-one meetings! With this intuitive tool, managers can effortlessly schedule and calendar your recurring One-on-One meetings! MApp’s cutting edge One-on-One tool helps you keep notes, access past notes, create tasks, and while doing all of this, personalize the approach using the impact narrative.",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739444607/MApp/UI/Images/Sales/one_one_one_compressed_sxnls2.png"
        },
        {
            id: 2, rowType: 'imageFirst', heading: 'Seamless Video Calls', justify: 'space-around', imageSpan: 8,
            text: "Bridge the gap between remote teams or geographically dispersed colleagues with our built-in video call feature. Engage in face-to-face conversations, build rapport, and enhance the quality of your interactions, no matter where you or your team members are located.",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739444566/MApp/UI/Images/Sales/one_on_one_2_vja3rr.png"
        },
        {
            id: 3, rowType: 'textFirst', heading: 'Automated Transcription Summaries', justify: 'center', textSpan: 14, marginLeft: '0px', imageSpan: 8, align: 'middle', textWidth: '80%',
            text: 'Our transcription feature generates comprehensive summaries by capturing key discussion points to autogenerate actions items and agenda for the next meeting.',
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739440060/MApp/UI/Images/Sales/one_one_one_3_gdmw0a.png"
        },
        {
            id: 4, rowType: 'imageFirst', heading: 'Give and Receive Instant Feedback', justify: 'space-around', marginLeft: '0px', imageSpan: 8, align: 'middle', textWidth: '80%',
            text: "Foster a culture of open communication and growth by exchanging real-time and instant feedback within the tool. With the integrated feedback tool, you can structure your feedback using Brainayan's CLAPS model, to articulate it in an objective and behavioural manner.",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739444567/MApp/UI/Images/Sales/one_on_one_4_ts6akr.png"
        },
        {
            id: 5, rowType: 'textFirst', heading: 'Tap into the SOUL', justify: 'space-around', imageSpan: 8, textSpan: 10,
            text: "Unlock a treasure trove of invaluable tips, insights, and best practices that are research-backed and delivered to you before your 1:1s. Powered by SOUL Identity, you receive tips based your team member's values, strengths, personality, and knowledge and skills. Get equipped to understand how to provide for what your team members need from you to take your leadership to the next level!",
            image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739444566/MApp/UI/Images/Sales/one_on_one_5_elojbw.png"
        }
        ]
    },
    {
        id: 3,
        tool: 'OKR', title: 'OKR', toolImage: okrs_2, type: "okr",
        rowSet: [
            {
                id: 1, rowType: 'textFirst', showbutton: true, heading: 'Objectives and Key Results', justify: 'center', textWidth: '100%', imageSpan: 12, textSpan: 10,
                text: "The OKR tool is a tool rich with various features that are thoughtfully designed. Setting up OKRs for an organization is a highly important activity to be able to align the entire organization and give it focus. Any OKR tool needs to be able to cascade OKRs effectively and be able to track the cascade easily. Our OKR tool will help you do just that. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449559/MApp/UI/Images/Sales/okr_1_nmsgsr.png"
            },
            {
                id: 2, rowType: 'imageFirst', heading: "Multi-faceted", justify: 'space-around', imageSpan: 8,
                text: "The tool allows you to create objectives, key results and initiatives, assign them to your team members, collaborate with others, update the status and track the progress. It has a way to request deadlines extensions and tracking them. More importantly, you can associate compentancies related to a particular KR before assigning it to somebody, so they know what they will have to prepped with to deliver the KR successfully and plan for their own upskilling.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449560/MApp/UI/Images/Sales/okr_2_fqvb0v.png"
            },
            {
                id: 3, rowType: 'textFirst', heading: 'Status', justify: 'center', textSpan: 14,
                marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "Not just that the OKRs can be created and tracked, they can also be viewed in multiple formats, making some of the complex information really visible, saving you valuable time and effort. Moreover, statuses from inviduals to teams to verticals to the org are updated seamlessly, giving you a live view of the organizational OKRs at any time.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449563/MApp/UI/Images/Sales/okr_3_j5vqbt.png"
            },
            {
                id: 4, rowType: 'imageFirst', heading: 'Chat', justify: 'space-around', marginLeft: '0px', imageSpan: 8,
                align: 'middle', textWidth: '80%',
                text: "Our OKR tool lets people collaborating on a particular KR chat inside the OKR tool itself. It helps real time communication among people that are collaborating on a goal. It also leaves a trail of discussions about the goal in once place and makes the development of the task easy to track. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449565/MApp/UI/Images/Sales/okr_4_c9ixgk.png"
            }]
    },
    {
        id: 4,
        tool: 'Feedback', title: 'Feedback', toolImage: feedback_2, type: "feedback",
        rowSet: [
            {
                id: 1, rowType: 'textFirst', showbutton: true, maxWidth: '100%', heading: 'Feedback', justify: 'center', imageSpan: 12, textSpan: 10, textWidth: '100%',
                text: "Building feedback culture is a business imperative. At the same time, feedback makes people feel unsafe and can trigger an amygdala hijack. Also, feedback is prone to biases that can get in the way of giving feedback in a respectful and objective manner. In spite of these challenges, we have to give and receive feedback and do it effectively. The feedback tool in MApp aims to help managers and their team members give each other feedback more effectively.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449548/MApp/UI/Images/Sales/feedback_1_runmiy.png"
            },
            {
                id: 2, rowType: 'imageFirst', maxWidth: '100%', heading: 'CLAPS', marginLeft: '0px', justify: 'space-around', imageSpan: 8,
                text: "The feedback tool offers the CLAPS model of Brainayan as a model to structure feedback. It address the anxiety around feedback and helps articulate feedback in an objective and behavioral manner. Remembering a framework and using it can become a tax on memory. This tools lays out the steps and give instructions, so managers can write good quality feedback without having to memorize a framework.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449549/MApp/UI/Images/Sales/feedback_2_compressed_bxtcp0.png"
            },
            {
                id: 3, rowType: 'textFirst', maxWidth: '100%', heading: 'Fill-in frames', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "To save time for managers, the tool also offers fill in frames. You just have to follow instructions and write the content related to the situation at hand and the overall feedback is pulled together for you. You of course can edit it before finally submitting, as MApp has flexibility at its core.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449552/MApp/UI/Images/Sales/feedback_3_n0qjue.png"
            },
            {
                id: 4, rowType: 'imageFirst', maxWidth: '100%', heading: 'Integrated with Kudos', marginLeft: '0px', justify: 'space-around', imageSpan: 8,
                text: "MApp has a Kudos tool and feedback is integrated with it. When you are giving positive feedback to somebody, you can choose to also give Kudos right from the feedback tool or even change your mind and just give Kudos to make it more visual and be able to share the accomplishment with more people as well.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449554/MApp/UI/Images/Sales/feedback_4_mznqmt.png"
            },
            {
                id: 5, rowType: 'textFirst', maxWidth: '100%', heading: 'View received, submitted feedback', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "You can view in one place all feedback you have received from different people in the organization at different times. You can also search on a person to see all the feedback they have given to you or all the feedback you have received from them. This makes it easy to establish a pattern of feedback, which is extremely powerful.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449556/MApp/UI/Images/Sales/feedback_5_ihbpup.png"
            }
        ]
    },
    {
        id: 5,
        tool: 'Coaching Conversation', title: 'Coaching Conversation', toolImage: Coaching_Conversation, type: "coaching",
        rowSet: [
            {
                id: 1, rowType: 'textFirst', showbutton: true, heading: 'Structured coaching', justify: 'center', imageSpan: 12, textSpan: 10, textWidth: '100%',
                text: "Our coaching tool helps you have a coaching conversation with your direct reports, as well as other people in the organization. The advantage of using the coaching tool is that it structures your coaching conversation and also lets you capture notes as a coach. That helps you keep track of the coaching engagment over a longer period of time.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449542/MApp/UI/Images/Sales/coaching_1_g57cw1.png"
            },
            {
                id: 2, rowType: 'imageFirst', heading: 'Tracking conversations', justify: 'space-around', imageSpan: 8,
                text: "Our tool allows you to track all past conversations, so you are up to date before your next coaching conversation. It shows you the next step that was agreep upon and it also allows you to remind the employee to act on their commitment.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449543/MApp/UI/Images/Sales/coaching_2_fyjcj8.png"
            },
            {
                id: 3, rowType: 'textFirst', heading: 'Learning resources', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "Like all other tools in MApp, there are learning resources that are available within the tool, so you can learn the GROW model, listen to a GROW conversation and feel confident about the GROW coaching conversation you are going to have.",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449544/MApp/UI/Images/Sales/coaching_3_slhtmw.png"
            },

        ]
    }, {
        id: 6,
        tool: 'Readiness Tool', title: 'Readiness Tool', toolImage: Readines_Tool, type: "readiness",
        rowSet: [
            {
                id: 1, rowType: 'textFirst', heading: 'Readiness ', showbutton: true, justify: 'center', imageSpan: 12, textSpan: 10, textWidth: '100%',
                text: "It's not enough to set clear goals. It's also important to set up employees for success. What happens if they don't have the required skills to be able to achieve their goals? That's where the Readiness tool comes to help. This tool helps you assess the skill level of an employee for a particulat goal and then decide what action you should take to uplevel them if needed. The Readiness tool help managers understand the upskilling needs of employees and be able to support them appropriately. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449567/MApp/UI/Images/Sales/readiness_1_fh6pcn.png"
            },
            {
                id: 2, rowType: 'imageFirst', heading: 'Handholding', justify: 'space-around', imageSpan: 8,
                text: "The tool uses a detailed model of knowledge-skills-experience-interest to decide the readiness of an individual. It may get a bit complex for managers to keep all the nitty-gritty in mind. The tool therefore, takes the first input and predicts what other possible combinations are and keeps guiding you through the rest of the assessment until you come to a readiness level. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449569/MApp/UI/Images/Sales/readiness_2_epxfq6.png"
            },
            {
                id: 3, rowType: 'textFirst', heading: 'Tips', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "The tool doesn't just stop at assessing the readiness level of an employee for a skill. It gives you suggestions for actions you can take based on the readiness level. That makes your support more informed and targeted. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449572/MApp/UI/Images/Sales/readiness_3_wc9du8.png"
            },
            {
                id: 4, rowType: 'imageFirst', heading: 'Skills', justify: 'space-around', marginLeft: '0px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "The model aims to assess readiness level of an employee for a skill. As you use the tool, it gives you a drop-down of skills already plugged in. But there's no guarantee that invetory of skills is going to suffice for your organization. Therefore, the tool offers the flexibility of adding skills, so you find relevant skills to assess your employees on. Moreover, you can do the readiness assessment not just for somebody who reports into you but for others as well, like a mentee you may have. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449574/MApp/UI/Images/Sales/readiness_4_c9s9ov.png"
            },
            {
                id: 3, rowType: 'textFirst', heading: 'Dashboard', justify: 'center', textSpan: 14, marginLeft: '80px', imageSpan: 8, align: 'middle', textWidth: '80%',
                text: "You get to see the readiness assessment you have submitted for others. You can filter them for a specific person, so you can view all their skills and readiness levels in one go. You can see the readiness levels you have received may be from your manager or mentor. ",
                image: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739449576/MApp/UI/Images/Sales/readiness_5_dbmmpf.png"
            },
        ]
    }
    // In future add the rowSet just in case how it has been added in above cases attribute whenever the below tools gets designed and ready to develop.
    , {
        // id: 7,
        tool: 'Trust Tool', title: 'Trust Tool', toolImage: trust

    }, {
        // id: 8,
        tool: 'Scape Tool', title: 'Scape Tool', toolImage: Scape_Tool,

    }, {
        // id: 9,
        tool: 'The Whole', title: 'The Whole', toolImage: the_svgwhole,

    }

]