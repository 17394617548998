import { survey_permission } from "./survey.permission";
import { user_profile_permissions } from "./userProfile.permission";

export const my_profile_permissions ={
    required_permission:['view_employee','view_user'].concat(survey_permission?.required_permission),
    award_badge_permission:['view_badge'],
    okr_permission:['view_okr','view_contributor'],
    survey_permission:survey_permission?.required_permission,
    competency_assessment_permission:['view_competencyassessment'],
    impact_narrative_permission:['view_impactnarrative'],
    user_profile_permissions:user_profile_permissions.profile_permissions
}