import React from "react";
import Tree from "react-d3-tree";
import '../../css/poc-org-tree-style.css'
import { organization_icons } from "modules/poc-dashboard/icons";
import { message, Tooltip, Dropdown, Menu, Modal, Button, Popover } from 'antd'
import EditVerticalContainer from "modules/poc-dashboard/containers/organization/EditVerticalContainer";
import EditTeamContainer from "modules/poc-dashboard/containers/organization/EditTeamContainer";
import OrganizationDetailView from "./OrganizationDetailView";

const OrgTreeView = (props) => {
    const { org_data, newNodeCreationChoice, convertToInput, clearInput, addTeam, addVertical, handleNodeSelection, selected_node, SetSelectedNode, UpdateNode, deleteNode, is_edit_mode, SetEditMode, expandNode, tree_key, recenterNode } = props

    const getinitialZoom = () => {
        let zoom = 0.6
        let width = window?.innerWidth
        if (width >= 1800) {
            zoom = 0.8
        }
        else if (width < 1200 && width > 600) {
            zoom = .5
        }
        else if (width <= 600) {
            zoom = .4
        }
        return zoom
    }

    const [zoom, SetZoom] = React.useState(getinitialZoom())

    const drawStepPath = (linkData, orientation) => {

        const { source, target } = linkData;
        let offSet = 0
        const deltaY = target.y - source.y;
        return orientation === 'horizontal'
            ? `M${source.y},${source.x} H${source.y + deltaY / 2} V${target.x} H${target.y}`
            : `M${source.x + offSet},${source.y} V${source.y + deltaY / 2} H${target.x} V${target.y}`;
    }

    const getDimension = () => {
        let element = document.getElementById('treeWrapper')
        if (element) {
            let bounding = element.getBoundingClientRect()
            let dimension = {
                width: bounding?.width,
                height: bounding?.height
            }
            return dimension
        } else {
            return {
                width: window.innerWidth - 240 || 1140,
                height: window.innerHeight - 150 || 750
            }
        }

    }

    const getTreeBorder = (type) => {
        let className = "tree-default-node-border"
        switch (type) {
            case "TEAM":
                className = "tree-team-node-border"
                break;
            case "SUB-VERTICAL":
                className = "tree-sub-vertical-node-border"
                break;
            case "VERTICAL":
                className = "tree-vertical-node-border"
                break;
            case "ORGANIZATION":
                className = "tree-organization-node-border"
                break;

            default:
                break;
        }
        return className
    }

    const getDynamicPathClass = ({ target }) => {
        let className = "tree-default-link"

        switch (target.data.attribute.node_type) {
            case "TEAM":
                className = "tree-team-link"
                break;
            case "VERTICAL":
                className = "tree-vertical-link"
                break;
            case "SUB-VERTICAL":
                className = "tree-sub-vertical-link"
                break;
            default:
                break;
        }
        // Style it as a link connecting two branch nodes by default.
        return className
    };

    const SvgNodeHeight = (attribute) => {
        let height = "180"
        if (is_edit_mode) {
            if (attribute?.node_type == "ORGANIZATION" || attribute?.create_node) {
                height = "100"
            } else {
                height = "180"
            }
        } else {
            if (attribute?.node_type == "ORGANIZATION") {
                height = "100"
            } else {
                height = "200"
            }
        }

        return height
    }


    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
        return (
            <g>
                <foreignObject
                    width="400"
                    height={SvgNodeHeight(nodeDatum?.attribute)}
                    x="-200"
                    y="-95"
                // onClick={toggleNode}
                >
                    <div className={is_edit_mode ? "poc-foreign-parent" : `${nodeDatum?.attribute?.node_type == "ORGANIZATION" ? "poc-foreign-parent" : "non-edit-poc-foreign-parent"}`} id={`node_obj_${nodeDatum?.node_id}`} >
                        <div className={`poc-node-template ${getTreeBorder(nodeDatum?.attribute?.node_type)}`} style={{ padding: nodeDatum?.attribute?.node_type == "ORGANIZATION" ? "16px" : "0px" }}>
                            <ForeignObjectView nodeDatum={nodeDatum} type={nodeDatum?.attribute?.node_type} toggleNode={toggleNode} />
                            {
                                (!is_edit_mode && nodeDatum?.attribute?.child_detail?.show_count) && (
                                    <div className="child-count-container">
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                            <div className="tree-child-count" onClick={() => { nodeAction(nodeDatum, toggleNode) }} style={{ backgroundColor: nodeDatum?.attribute?.child_detail?.color }}>
                                                <span>{nodeDatum?.attribute?.child_detail?.count}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </foreignObject>
            </g>
        );
    };

    const org_structure_icon = {
        "TEAM": organization_icons.team_icon,
        "VERTICAL": organization_icons.vertical_icon,
        "SUB-VERTICAL": organization_icons.sub_vertical_icon
    }
    const action_btns = {
        "TEAM": organization_icons.tree_team_btn,
        "VERTICAL": organization_icons.tree_vertical_btn,
        "SUB-VERTICAL": organization_icons.tree_sub_vertical_btn
    }

    const options = {
        "OTHER": [
            {
                key: "edit",
                title: "Edit",
                disabled: false,
            },
            {
                key: "delete",
                title: "Delete",
                disabled: false,
            },
            {
                key: "detail",
                title: "Details",
                disabled: false,
            }
        ],
        "ORGANIZATION": [
            {
                key: "detail",
                title: "Details",
                disabled: false,
            }
        ]
    }

    const MenuDropDown = (type, id) => (
        <Menu className='three-dot-menu'>
            {(is_edit_mode ? options[type] : options["ORGANIZATION"]).map((val) =>
            (<Menu.Item key={val.key} onClick={(e) => { handleNodeSelection(id, val?.key) }} className='org-table-body-label' style={{ fontWeight: "500", padding: '.5em', cursor: "pointer" }}>
                {val.title}
            </Menu.Item>)
            )}
        </Menu>
    )

    const nodeAction = (nodeDatum, toggleNode) => {
        if (!is_edit_mode) {
            expandNode(nodeDatum)
        } else {
            newNodeCreationChoice(nodeDatum?.node_id, toggleNode)
        }
    }

    const ForeignObjectView = (value) => {
        const { type, nodeDatum, toggleNode } = value
        const [input_data, SetInputData] = React.useState()
        const submitValue = () => {
            if (!input_data || input_data?.trim() == "") {
                message.error("Please provide the name")
                return
            }

            let data = {
                node_id: nodeDatum?.node_id,
                name: input_data?.trim(),
                organization: nodeDatum?.attribute?.detail?.organization?.id,
                type,
                parent_id: nodeDatum?.parent_node
            }

            if (data?.type == "TEAM") {
                addTeam(data)
            } else {
                addVertical(data)
            }
        }
        if (nodeDatum?.attribute?.create_node) {
            return (
                <div style={{ width: "100%", padding: "16px" }} className="tree-input-container">
                    <input type="text" value={input_data} onChange={(e) => { SetInputData(e?.target?.value) }} id={`input_${nodeDatum?.node_id}`} placeholder={`Enter ${type?.toLowerCase()} name`} className="tree-input-box" />

                    <img src={action_btns[type]} alt="" style={{ cursor: "pointer" }} onClick={() => { submitValue() }} />
                    <img src={organization_icons.node_delete_icon} alt="" style={{ cursor: "pointer" }} onClick={() => { clearInput() }} />
                </div>
            )
        }
        else if (type == "CREATE_CHOICE_NODE") {
            let node_type = nodeDatum?.attribute?.create_types?.length == 2 ? "DUAL_TYPE" : "MONO_TYPE"
            return (
                <div style={{ width: "100%", height: "100%" }} id="create_choice_container">
                    {
                        node_type == "DUAL_TYPE" ?
                            <div className="tree-choice-type-container">
                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <div className="dual-type-main-border">
                                        <div className="tree-create-circle tree-choice-circle-left" id="create_choice_btn1" onClick={() => { convertToInput(nodeDatum?.node_id, nodeDatum?.attribute?.create_types[0]?.type) }}><h4 style={{ lineHeight: "0", color: "#FFFFFF" }}>+</h4></div>
                                        <div className="tree-create-circle tree-choice-circle-right" id="create_choice_btn2" onClick={() => { convertToInput(nodeDatum?.node_id, nodeDatum?.attribute?.create_types[1]?.type) }}><h4 style={{ lineHeight: "0", color: "#FFFFFF" }}>+</h4></div>
                                        <div className="tree-choice-btn tree-choice-left-btn" id="create_choice_btn3" onClick={() => { convertToInput(nodeDatum?.node_id, nodeDatum?.attribute?.create_types[0]?.type) }}>Create {nodeDatum?.attribute?.create_types[0]?.type?.toLowerCase()}</div>
                                        <div className="tree-choice-btn tree-choice-right-btn" id="create_choice_btn4" onClick={() => { convertToInput(nodeDatum?.node_id, nodeDatum?.attribute?.create_types[1]?.type) }}>Create {nodeDatum?.attribute?.create_types[1]?.type?.toLowerCase()}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="tree-choice-type-container">
                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <div className="mono-type-main-border">
                                        <div className="tree-create-circle tree-choice-circle-right" id="create_choice_btn5" onClick={() => { convertToInput(nodeDatum?.node_id, nodeDatum?.attribute?.create_types[0]?.type) }}><h4 style={{ lineHeight: "0", color: "#FFFFFF" }}>+</h4></div>
                                        <div className="tree-choice-btn tree-choice-right-btn" id="create_choice_btn6" onClick={() => { convertToInput(nodeDatum?.node_id, nodeDatum?.attribute?.create_types[0]?.type) }}>Create {nodeDatum?.attribute?.create_types[0]?.type?.toLowerCase()}</div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        }
        else if (type === "ORGANIZATION") {
            return (
                <div className="tree-row-spacebetween" style={{ height: "100%" }}>
                    <div onClick={() => { nodeAction(nodeDatum, toggleNode) }} className="tree-row tree-data-overflow" style={{ alignItems: "center", width: "100%" }}>
                        <div className="tree-large-profile-container">
                            {
                                nodeDatum?.attribute?.detail?.organization.logo && (
                                    <img src={nodeDatum?.attribute?.detail?.organization?.logo} alt="" className="tree-profile-pic" />
                                )
                            }

                        </div>
                        <h4 className="tree-title">{nodeDatum?.name}</h4>
                    </div>
                    <div className="tree-menu-container">
                        <Dropdown overlay={MenuDropDown(nodeDatum?.attribute?.node_type, nodeDatum?.node_id)} placement="bottomLeft"
                            overlayStyle={{ width: '160px' }}  >
                            <img src={organization_icons.vertical_dot} className="tree-menu-icon " alt="" />
                        </Dropdown>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="tree-node-main-container">
                    <div style={{ width: "100%", padding: "16px" }}>
                        <div className="tree-row-spacebetween" >
                            <div className="tree-row tree-data-overflow" onClick={() => { nodeAction(nodeDatum, toggleNode) }} style={{ alignItems: "center", width: "100%" }}>
                                <img src={org_structure_icon[type]} alt="" style={{ width: "36px" }} />
                                <Tooltip title={nodeDatum?.name}>
                                    <h4 className="tree-title">{nodeDatum?.name}</h4>
                                </Tooltip>
                            </div>
                            <div className="tree-menu-container">
                                <Dropdown overlay={MenuDropDown("OTHER", nodeDatum?.node_id)} placement="bottomLeft"
                                    overlayStyle={{ width: '160px' }}  >
                                    <img src={organization_icons.three_dots} className="tree-menu-icon" alt="" />
                                </Dropdown>
                            </div>
                        </div>
                        {
                            nodeDatum?.attribute?.manager?.length ?
                                <div className="tree-row-spacebetween" onClick={() => { nodeAction(nodeDatum, toggleNode) }} style={{ marginTop: "16px" }}>
                                    <div className="tree-row tree-data-overflow" style={{ alignItems: "center", width: "100%" }}>
                                        <div className="tree-medium-profile-container">
                                            <img src={nodeDatum?.attribute?.manager[0]?.profile_image || organization_icons?.profile_icon} className="tree-profile-pic" alt="" />
                                        </div>
                                        <h4 className="tree-profile-name">
                                            <span className="tree-role-label">{nodeDatum?.attribute?.manager[0]?.role?.toLowerCase()}</span>
                                            <br />{nodeDatum?.attribute?.manager[0]?.name}</h4>
                                    </div>
                                </div>
                                : <div onClick={() => { nodeAction(nodeDatum, toggleNode) }} style={{ height: "200%", width: "100%" }}>

                                </div>
                        }

                    </div>
                    <div onClick={() => { nodeAction(nodeDatum, toggleNode) }} className={`tree-node-bottom-container ${getTreeBorder(type)}`} style={{ borderLeft: "none", borderRight: "none", borderBottom: "none" }}>
                        <h4 className="tree-title" style={{ fontSize: "16px" }} >{nodeDatum?.attribute?.employee} Employee</h4>
                    </div>
                </div>
            )
        }
    }

    const delete_warning = {
        "TEAM": "Are you sure you want the delete this team?",
        "VERTICAL": "Are you sure you want the delete this vertical?.if delete this vertical all the team and sub-vertical inside this vertical will be deleted to.",
        "SUB-VERTICAL": "Are you sure you want the delete this vertical?.if delete this vertical all the team inside this vertical will be deleted to."
    }

    let legend_list = [
        {
            label: "Organization",
            color: "#0C8AFF"
        },
        {
            label: "Vertical",
            color: "#21CCD7"
        },
        {
            label: "Sub Vertical",
            color: "#6876ED"
        },
        {
            label: "Team",
            color: "#21D796"
        }
    ]

    return (
        <>
            {
                org_data && (
                    <div style={{ width: "100%", height: "100%" }}>

                        <div className="tree-mode-section">
                             
                            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                                {
                                    legend_list?.map(i => (
                                        <div style={{ display: "flex", flexDirection: "row", gap: "0.25em", width: "100%", alignItems: "center" }}>
                                            <div className="legends-container" style={{ background: i?.color }}></div> <p className="legend-label">{i?.label}</p>
                                        </div>
                                    ))
                                }
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                                <Button onClick={() => recenterNode()} className="tree-recenter-btn"> <img src={organization_icons?.poc_tree_recenter} style={{ marginRight: "4px" }} alt="" /> <span style={{ margin: "0px" }}>Recenter</span></Button>
                                <div className="tree-mode-switch">
                                    <div className={`mode-inactive ${!is_edit_mode ? "mode-active" : ""}`} onClick={(e) => SetEditMode(false)}>View mode</div>
                                    <div className={`mode-inactive ${is_edit_mode ? "mode-active" : ""}`} onClick={(e) => SetEditMode(true)}>Edit mode</div>
                                </div>
                            </div>
                        </div>

                        <div id="treeWrapper" className="poc-org-tree-container">
                            <Tree
                                key={tree_key}
                                data={org_data}
                                // enableLegacyTransitions={true}
                                orientation="vertical"
                                zoom={zoom}
                                renderCustomNodeElement={renderRectSvgNode}
                                translate={{
                                    x: window?.innerWidth / 2 - 150,
                                    y: 100,
                                }}
                                separation={{
                                    siblings: 1,
                                    nonSiblings: 1,
                                }}
                                nodeSize={{
                                    x: 500,
                                    y: 350,
                                }}
                                collapsible={false}
                                pathFunc={drawStepPath}
                                pathClassFunc={getDynamicPathClass}
                                dimensions={getDimension()}
                            />
                        </div>
                    </div>
                )
            }

            <Modal
                visible={selected_node}
                destroyOnClose={true}
                footer={false}
                closable={false}
                centered={true}
                width={selected_node?.type == "detail" ? "90%" : selected_node?.type == "delete" ? "600px" : "700px"}
                className={selected_node?.type == "detail" ? "organization-detail-view" : ""}
                style={{ maxWidth: "90%" }}
            >
                {
                    (selected_node?.type == "edit" && (selected_node?.node_type == "VERTICAL" || selected_node?.node_type == "SUB-VERTICAL")) && (
                        <EditVerticalContainer {...props} selected_node={selected_node} onClose={() => { SetSelectedNode(null) }} UpdateNode={UpdateNode} />
                    )
                }

                {
                    (selected_node?.type == "edit" && selected_node?.node_type == "TEAM") && (
                        <EditTeamContainer {...props} selected_node={selected_node} onClose={() => { SetSelectedNode(null) }} UpdateNode={UpdateNode} />
                    )
                }

                {
                    (selected_node?.type == "detail") && (
                        <OrganizationDetailView {...props} selected_node={selected_node} detail={selected_node?.node?.attribute} onClose={() => { SetSelectedNode(null) }} />
                    )
                }

                {
                    (selected_node?.type == "delete") && (
                        <div style={{ display: "flex", flexDirection: 'column', gap: '1em', padding: '1em' }}>
                            <h4 className="poc-dashboard-card-title" style={{ fontSize: "1.5em", fontWeight: 600, whiteSpace: "normal", textAlign: "center" }}>
                                {delete_warning[selected_node?.node_type]}</h4>
                            <div style={{ width: "100%", display: 'flex', gap: "1em", justifyContent: 'center', alignItems: "center", marginTop: "1em" }}>
                                <Button className="organization-cancel-btn" onClick={() => SetSelectedNode(null)}>Cancel</Button>
                                <Button className="organization-delete-btn" onClick={() => { deleteNode(selected_node?.node) }}>Delete</Button>
                            </div>
                        </div>

                    )
                }

            </Modal>
        </>
    )
}

export default OrgTreeView