import gql from 'graphql-tag';

export const PROFILE_TYPE = gql`
  fragment ProfileType on ProfileType {
    id
    gender
    dob
    education
    married
    village
    district
    phoneNumber
    preferredLanguage
    residenceDuringSecondarySchool
    residenceDuringCollegeUniversity
    residenceDuringMajorPartOfCareer
    address
    profileImage
    workExperience
    nationality
  }
`;
