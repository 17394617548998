
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Row, Col, Form, Modal, Steps, message, Button } from 'antd';
import { MetaTags, getIntFromString, InputValuesObject, getUniqueListBy, setSurveyCache, getSurveyCache, getQuestionBase64StringFromInt, removeSurveyCache } from '../../look';
import HOME_ROUTE from '../../home/route'
import GenericSurveyView from 'modules/survey-look/containers/generic-survey-view';
import quit_icon from '../../assets/quit-survey-icon.svg'
import warning_icon from '../../assets/warning-icon.svg'
import QuizLoading from 'modules/survey/components/QuizLoading';
import step_icon from '../../assets/stepper-complete-icon.svg'
import stepper_pending from '../../assets/stepper-pending.svg'
import moment from 'moment-timezone';
import SurveyPreview from '../components/survey-preview';
import { survey_with_preview } from '../../../config';
import { Prompt } from "react-router-dom";
const GenericQuizView = props => {
  const { loading, orgSurveyById, onSubmit, SurveyById, navigateRoute, setshowTermsAndCondition, surveyPermitted } = props;
  const QuesDiv = {
    margin: 'auto',
    width: ' 100%',
    height: '100%',
    overflow: 'auto',
    padding: `${props => !props.currentIsMatrix && '24px'}`
  };

  const ref = React.useRef();
  const [showOk, setShowOk] = React.useState([false]);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [currentSection, setCurrentSection] = React.useState(1);
  const [formValues, setFormValues] = React.useState([]);
  const [startTime, setStartTime] = React.useState(new Date().getTime());
  const [page_count, setPageCount] = React.useState()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [questionValues, setQuestionValues] = React.useState([])
  const [totalquestionLength, setTotalQuestionLength] = React.useState()
  const [allQuestions, setAllquestions] = React.useState([])
  const [quit_survey, setQuitSurvey] = React.useState(false)
  const [form] = Form.useForm();
  const containerRef = React.useRef(null);
  const Step = Steps.Step;
  const [open_questions, SetOpenQuestion] = React.useState([])
  const form_values = form.getFieldsValue(true);
  const [preview_detail, SetPreviewDetail] = React.useState()

  const exit_warning = React.useRef(false)

  const handleBeforeUnload = (event) => {
    console.log("exit_warning.current", exit_warning.current);
    if (exit_warning.current) {
      event.preventDefault();
      // Chrome requires the returnValue to be set
      event.returnValue = 'YOu have unsaved changes';
      return event;
    }
  };

  React.useEffect(() => {

    // Attach the event listener to the window object
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Clean up the event listener when the component unmounts
    return () => {
      exit_warning.current = false
      window.removeEventListener('beforeunload', () => { });
    };
  }, []);

  React.useEffect(() => {
    if (form) {
      if (Object.keys(form.getFieldsValue(true))?.length) {
        exit_warning.current = true
      } else {
        exit_warning.current = false
      }
    }
  }, [form.getFieldsValue(true)])

  React.useEffect(() => {
    if (!orgSurveyById?.id) return;
    const surveyId = getIntFromString(orgSurveyById?.id);
    const { data } = getSurveyCache(surveyId);
    if (data?.length) {
      setFormValues(data);
      let form_values = {};
      data.forEach(i => {
        form_values[getQuestionBase64StringFromInt(i?.questionId)] = i?.answer;
      });
      form.setFieldsValue(form_values);

      if (orgSurveyById?.termsConditions && surveyPermitted) {
        setshowTermsAndCondition(false);
      }
    } else if (orgSurveyById?.termsConditions && surveyPermitted) {
      setshowTermsAndCondition(true);
    }
  }, [orgSurveyById]);

  React.useEffect(() => {
    if (!formValues || !orgSurveyById?.id) return;
    const surveyId = getIntFromString(orgSurveyById?.id);
    setSurveyCache(surveyId, JSON.stringify({ data: formValues }));

  }, [formValues, orgSurveyById]);

  React.useEffect(() => {
    return () => {
      if (orgSurveyById) {
        const surveyId = getIntFromString(orgSurveyById?.id);
        removeSurveyCache(surveyId)
      }
    }
  }, [orgSurveyById])

  React.useEffect(() => {
    if (questionValues?.length) {
      let opened_questions = [questionValues[0]?.matrix ? questionValues[0]?.id : questionValues[0]?.questionSet[0]?.id].concat([...open_questions, ...Object.keys(form_values)])
      SetOpenQuestion(opened_questions)
    }
  }, [questionValues])

  React.useEffect(() => {
    if (orgSurveyById || SurveyById) {
      let question = [].concat.apply(
        [],
        (orgSurveyById ? orgSurveyById : SurveyById)?.groupSet?.edges
          .sort(function (a, b) {
            return a.node.sequence - b.node.sequence;
          })
          .map(({ node: grpSet }, idx) =>
            grpSet.matrix
              ? { ...grpSet, questionSet: grpSet?.questionSet?.edges?.map(({ node }) => node) }
              : grpSet?.questionSet?.edges?.map(({ node: questionSet }) => ({
                ...grpSet,
                questionSet: [questionSet]
              }))
          )
      );
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
      setAllquestions(question)
      setTotalQuestionLength(question?.length)
      let page_count = Math.ceil(question?.length / 10)
      setPageCount(page_count)

      setQuestionValues(question.slice(0, 10))
    }
  },
    [orgSurveyById, SurveyById])
  const currentIsMatrix = questionValues[currentSection - 1]?.matrix;
  let totalLength = totalquestionLength;
  const renderFormSections = survey => {

    function timeoutFunc(srNo) {
      const id = setTimeout(() => {
        nextStep(form.getFieldsValue(true), srNo);
      }, 500)

      setTimeoutId(id);
    }
    function closetimer() {
      clearTimeout(timeoutId);
    }

    function getValue(value, name) {
      if (!currentIsMatrix) {
        const questionFiltered = questionValues.filter(q => {
          return Array.isArray(q?.questionSet) && q?.questionSet?.filter(qs => qs.id === name).length > 0;
        })[0]?.questionSet[0];
        switch (questionFiltered?.choiceSet?.edges[0]?.node?.inputType) {
          case InputValuesObject.date:
            return value?.format('YYYY/MM/DD');
          case InputValuesObject.datetime:
            return value?.format('YYYY/MM/DD HH:mm:ss');
          case InputValuesObject.month:
            return value?.format('M');
          case InputValuesObject.time:
            return moment(value, ['h:mm A'])?.format('HH:mm:ss');
          case InputValuesObject.week:
            return value?.week();
          case InputValuesObject.range:
            return value === undefined || value === 0 ? 0 : value;
          default:
            return value || '';
        }
      } else {
        return value || '';
      }
    }

    function getResponseDuration(endTime, value, k) {
      let time = 0;
      const existingValue = formValues.filter(f => f.questionId === getIntFromString(k))[0];

      if (!currentIsMatrix) {
        time =
          questionValues[currentSection - 1]?.questionSet[0].id === k
            ? existingValue
              ? existingValue?.responseDuration + (endTime - startTime) / 1000 // returns time in seconds
              : (endTime - startTime) / 1000 // returns time in seconds
            : existingValue?.responseDuration || 0;
      } else {
        time = existingValue
          ? existingValue?.answer === '' || existingValue?.answer === undefined
            ? value !== '' || value !== undefined
              ? 0
              : (endTime - startTime) / 1000
            : existingValue?.responseDuration
          : value === '' || value === undefined
            ? 0
            : (endTime - startTime) / 1000;
      }
      return time;
    }

    const jumbTonext = (index) => {
      const current_player = document.getElementById(`${index - 1}`)
      if (current_player) {
        current_player.scrollIntoView({
          behavior: 'smooth', block: 'center',
          inline: 'center'
        });
      }
    }

    const nextStep = values => {
      if (currentSection !== totalLength) ref.current && ref.current.next()
      closetimer();
      setShowOk(questionValues.map(() => false));

      const endTime = new Date().getTime();

      setCurrentSection(Object.keys(values)?.length + 1)
      let input = [];
      Object.keys(values).map((k, i) => {
        input.push({
          questionId: getIntFromString(k),
          answer: String(getValue(values[k], k)),
          responseDuration: getResponseDuration(endTime, values[k], k)
        });
      });

      setFormValues(getUniqueListBy([...formValues, ...input], "questionId"));
      setStartTime(endTime);
      let result = getUniqueListBy([...formValues, ...input], "questionId");

      return result

    };

    const goToNextPage = (currentPage) => {
      if (currentPage < page_count) {
        let nextPage = currentPage + 1
        if (nextPage === page_count) {
          let nextStart = currentPage * 10
          setQuestionValues(allQuestions.slice(nextStart, allQuestions?.length))
          setCurrentSection(currentSection + 1)
          setCurrentPage(nextPage)
        }
        else {
          let nextStart = currentPage * 10
          setQuestionValues(allQuestions.slice(nextStart, nextStart + 10))
          setCurrentSection(currentSection + 1)
          setCurrentPage(nextPage)
        }

      }
    }

    const gotToNext = () => {
      let question_in_page = questionValues?.map(i => { return { group_id: i?.id, matrix: i?.matrix, required_questions: i?.questionSet?.filter(i => i?.required)?.map(i => i?.id) } })
      let values = { ...form_values }
      Object.entries(values).forEach(i => {
        let unwanted = [undefined, null, ""]
        if (unwanted?.includes(i[1])) {
          delete values[i[0]]
        } else if (typeof (i[1]) == "string") {
          if (unwanted?.includes(i[1]?.trim())) {
            delete values[i[0]]
          }
        }
      })
      let not_answered_data = null
      question_in_page.forEach(i => {
        if (!i?.required_questions.every(rq => Object.keys(values)?.includes(rq))) {
          if (!not_answered_data) {
            not_answered_data = i
          }
        }
      })

      let field_error_list = form?.getFieldsError()
      let validation_error = field_error_list?.find(i => i?.errors?.length)
      if (not_answered_data || validation_error) {
        let not_answered = not_answered_data ?
          not_answered_data?.matrix ? not_answered_data?.group_id : not_answered_data?.required_questions[0]
          :
          validation_error?.name[0]
        let all_ids = questionValues?.map(i => { return i?.matrix ? [i?.id] : i?.questionSet?.map(i => i?.id) })
        all_ids = [].concat.apply([], all_ids)
        let not_answered_index = all_ids?.indexOf(not_answered)
        message.destroy()
        message.error('Please answer all the required questions')
        let opened_questions = [not_answered].concat(open_questions)
        SetOpenQuestion(opened_questions)
        jumbTonext(((currentPage - 1) * 10) + not_answered_index + 1)
      }
      else {
        goToNextPage(currentPage)
        if (document) {
          if (document.body) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        }
      }
    }

    const customDot = (icon, { status }) => {
      switch (status) {
        case 'process':
          return <span className="custom-dot custom-dot-process"><img src={step_icon} alt="" style={{ width: "25px", marginLeft: "-5px" }} /></span>;
        case 'wait':
          return <span className="custom-dot custom-dot-finish"><img src={stepper_pending} alt="" style={{ width: "18px", marginLeft: "-2px" }} /></span>;
        default:
          return <span className={`custom-dot custom-dot-${status}`}><img src={stepper_pending} alt="" style={{ width: "18px", marginLeft: "-2px" }} /></span>;
      }
    };

    const onAnswerChange = (id, value, type) => {

      let question_in_page = questionValues?.map(i => { return i?.matrix ? { id: i?.id } : i?.questionSet })
      question_in_page = [].concat.apply([], question_in_page)?.map(i => i?.id)
      let index_of_question = question_in_page?.indexOf(id)
      if (index_of_question >= 0) {
        let next_question = question_in_page[(index_of_question + 1)]
        if (next_question && !form_values[next_question]) {
          let opened_questions = [next_question].concat(open_questions)
          SetOpenQuestion(opened_questions)
          jumbTonext(((currentPage - 1) * 10) + index_of_question + 2)
        }
      }

      if (type == InputValuesObject.range && value === 0) {
        form.setFieldsValue(
          _.set(
            form.getFieldsValue(true),
            id,
            value
          )
        )

      }

      if (type == InputValuesObject.range) {
        let input = [
          {
            questionId: getIntFromString(id),
            answer: String(value),
            responseDuration: 0
          }
        ]
        setFormValues(getUniqueListBy([...formValues, ...input], "questionId"));
      }
    }

    const ValidateSubmit = (data) => {
      let question_in_page = questionValues?.map(i => { return { group_id: i?.id, matrix: i?.matrix, required_questions: i?.questionSet?.filter(i => i?.required)?.map(i => i?.id) } })
      let values = { ...form_values }
      Object.entries(values).forEach(i => {
        let unwanted = [undefined, null, ""]
        if (unwanted?.includes(i[1])) {
          delete values[i[0]]
        } else if (typeof (i[1]) == "string") {
          if (unwanted?.includes(i[1]?.trim())) {
            delete values[i[0]]
          }
        }
      })
      let not_answered_data = null
      question_in_page.forEach(i => {
        if (!i?.required_questions.every(rq => Object.keys(values)?.includes(rq))) {
          if (!not_answered_data) {
            not_answered_data = i
          }
        }
      })

      let field_error_list = form?.getFieldsError()
      let validation_error = field_error_list?.find(i => i?.errors?.length)
      if (not_answered_data || validation_error) {
        let not_answered = not_answered_data ?
          not_answered_data?.matrix ? not_answered_data?.group_id : not_answered_data?.required_questions[0]
          :
          validation_error?.name[0]
        let all_ids = questionValues?.map(i => { return i?.matrix ? [i?.id] : i?.questionSet?.map(i => i?.id) })
        all_ids = [].concat.apply([], all_ids)
        let not_answered_index = all_ids?.indexOf(not_answered)
        message.destroy()
        message.error('Please answer all the required questions')
        let opened_questions = [not_answered].concat(open_questions)
        SetOpenQuestion(opened_questions)
        jumbTonext(((currentPage - 1) * 10) + not_answered_index + 1)
      }
      else if (data) {
        if ((!survey_with_preview || !survey_with_preview?.length || survey_with_preview?.includes(getIntFromString(orgSurveyById?.id)))) {
          SetPreviewDetail(data)
          if (document) {
            if (document.body) {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }
          }
        } else {
          exit_warning.current = false
          onSubmit(data)
        }
      }
    }

    const goToPrevious = () => {
      let prev_page = currentPage - 1
      let list = allQuestions.slice((prev_page - 1) * 10, ((prev_page - 1) * 10) + 10)
      setQuestionValues(list)
      setCurrentPage(prev_page)
    }

    const RetakeSurvey = () => {
      setFormValues([])
      form.resetFields()
      let list = allQuestions.slice(0, 10)
      setQuestionValues(list)
      setCurrentPage(1)
      SetPreviewDetail(null)
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }

    }
    return (
      <div>
        {
          survey ?
            <div ref={containerRef}>
              <MetaTags title={survey[currentSection]?.label || survey?.name} description="This is survey page." />


              <Form
                form={form}
                name={survey?.name ? survey?.name : 'value'}
                onFinish={values => (currentPage === page_count ? ValidateSubmit(nextStep(values)) : nextStep(values))}
              >
                <Row>
                  <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <Row style={{ padding: "0rem 2rem", marginTop: "1rem" }} justify='space-between' align='center'>
                      <h3 className="generic-survey-assessment-heading">
                        {survey[currentSection]?.label || survey?.name}
                      </h3>
                    </Row>
                    {
                      !preview_detail ?
                        <Col style={{ width: '100%' }}>
                          <div style={QuesDiv}>

                            <div className='generic-survey-skil-stepper'>
                              <Steps direction="vertical" current={currentPage > 1 ? (currentSection - 1) - ((currentPage - 1) * 10) : currentSection - 1} progressDot={customDot}>
                                {questionValues?.map((grpSet, idx) => (
                                  <Step className='step-sroll-transition' key={currentSection - 1} id={((currentPage - 1) * 10) + idx} description={

                                    <GenericSurveyView
                                      {...props}
                                      onAnswerChange={(id, value, type) => { onAnswerChange(id, value, type) }}
                                      survey={orgSurveyById || SurveyById}
                                      key={((currentPage - 1) * 10) + idx}
                                      srNo={(((currentPage - 1) * 10) + idx) + 1}
                                      form={form}
                                      formValues={formValues}
                                      form_values={form_values}
                                      currentIsMatrix={currentIsMatrix}
                                      currentSection={currentSection}
                                      matrix={grpSet?.matrix}
                                      timeoutFunc={timeoutFunc}
                                      closetimer={closetimer}
                                      nextStep={e => nextStep(form.getFieldsValue(true), e)}
                                      sectionLabel={{ lable: grpSet?.label, id: grpSet?.id }}
                                      sectionSequence={grpSet?.sequence}

                                      questionList={
                                        Array.isArray(grpSet?.questionSet)
                                          ? grpSet?.questionSet?.map(node => node)
                                          : grpSet?.questionSet?.edges?.map(({ node }) => node)
                                      }
                                      open_questions={open_questions}
                                      showOk={showOk[0]}
                                      setShowOk={e =>
                                        setShowOk(grpSet?.questionSet?.map((q, qi) => (e ? qi === currentSection - 1 : false)))
                                      }
                                    />

                                  } />

                                ))}
                                <Step id={((currentPage - 1) * 10) + (questionValues?.length + 1)} />
                              </Steps>
                            </div>


                          </div>



                          <Row className='generic-survey-skil-stepper' justify='space-between' align='center'>
                            <Button className='generic-survey-quit-btn' style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", cursor: "pointer" }} onClick={() => setQuitSurvey(true)}>
                              <img src={quit_icon} alt="" />
                              Quit survey
                            </Button>
                            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                              {
                                currentPage > 1 && (
                                  <Button className='generic-survey-next-btn' onClick={() => goToPrevious()}>
                                    PREVIOUS
                                  </Button>
                                )
                              }

                              {currentPage !== page_count ? <Button className='generic-survey-next-btn' onClick={() => gotToNext()}>
                                NEXT
                              </Button> :
                                <button className='generic-survey-action-btn' htmlType="submit" onClick={() => { ValidateSubmit() }}>
                                  {(!survey_with_preview || !survey_with_preview?.length || survey_with_preview?.includes(getIntFromString(orgSurveyById?.id))) ? "PREVIEW" : "SUBMIT"}
                                </button>}
                            </div>
                            <div></div>
                          </Row>
                        </Col>
                        : <SurveyPreview preview_detail={preview_detail} survey={orgSurveyById} survey_question_list={orgSurveyById?.groupSet?.edges?.flatMap(({ node }) => node?.questionSet?.edges?.map(q => q?.node))} {...props} RetakeSurvey={RetakeSurvey} onSubmit={(result) => { exit_warning.current = false; onSubmit(result) }} />
                    }

                  </Col>
                </Row>
              </Form>
            </div> : ""
        }
        <Modal
          footer={null}
          style={{ padding: 10, borderRadius: "20px", maxWidth: "600px" }}
          visible={quit_survey}
          closable={false}
          width='100%'
          centered={true}
          className="custom-modal"
        >
          <Row justify='center' align='center' style={{ padding: "1rem", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', width: "100%" }}>
            <img src={warning_icon} alt="" />
            <h3 className="generic-survey-quit-title" style={{ marginTop: "1rem" }}>Are you sure you want to exit this survey ?</h3>
            {/* <p className="generic-survey-quit-para">We will save all your current responses. you can choose to continue from here the next time you start this survey.</p> */}
            <Row justify="center" align="center" >
              <button className="generic-survey-remove-btn" style={{ margin: "5px" }} onClick={() => {  setQuitSurvey(false) }}>NO</button>
              <button className="generic-survey-submit-btn" style={{ margin: "5px" }} onClick={() => {  navigateRoute(HOME_ROUTE.userdashboard) }}>YES</button>
            </Row>
          </Row>
        </Modal>
        <Prompt when={exit_warning.current} message={"You have unsaved changes. Are you sure you want to exit the page."} />

      </div>
    );
  };

  return (
    <MobilePadding>
      <div>
        {loading && <QuizLoading />}
        {!loading && orgSurveyById ? renderFormSections(orgSurveyById) : renderFormSections(SurveyById)}
      </div>
    </MobilePadding>
  );
};

export default GenericQuizView;

const MobilePadding = styled.div`
  /* background: #f5f5f5; */
  // margin: -5vh;
  /* padding: 5vh 0; */
  min-height: 100vh;
 
  @media only screen and (max-width: 480px) {
    padding: 0 12px;
  }
`;
