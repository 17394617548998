import React from 'react'
import ROUTE from '../route';
import USER_ROUTE from '../../user/route';
import { message, Spin } from 'antd';
import { compose, removeItem } from '../../core';
// import { withCreateResponse } from '../../survey/containers/SurveyOperations';
import { withCreateResponse, withSurveyByIdNewOptimized } from './diversityOperations'
import DiversityQuizView from '../components/DiversityQuizView';
import { getIntFromString, globalPermissionValidator, removeSurveyCache } from '../../look';
import { withCreateResponseOptimized} from '../../survey/containers/SurveyOperations'
import SurveyTermsAndCondition from '../../survey/components/SurveyTermsAndCondition'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import mapp_learning_path_routes from 'modules/learning-path/routes';
import { withEditProfile } from 'modules/user/containers/UserOperations';
import { user_profile_permissions } from 'Permissions/userProfile.permission';
const DiversityHome = (props) => {
    const { error, history, createResponse, orgSurveyById, SurveyById, editProfile, location, navigateRoute, me, userPermission} = props;
    const startTime = new Date().getTime();
    const [prevTotalDuration, setPrevTotalDuration] = React.useState(0);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [showTermsAndCondition, setshowTermsAndCondition] = React.useState();
    React.useEffect(()=>{
        if(location){
            let  query =location.search?.replace('?','')
            let data = query.split('=')
            console.log("location",data)
        }
    },[location])
    if (error) {
        if (error.message === 'GraphQL error: Permission Denied.') {
            // setRedirect(true);
            setTimeout(3000, history.push(USER_ROUTE.login));
        }
    }

    const editUserProfile = async (data) => {
        const questionIds = {
            gender: 36,
            education: 38,
            work_exp: 662,
            nationality: 665,
        }

        const gender_list ={
            "Male": "M",
            "Female": "F",
            "Transgender": "Transgender",
            "Gender Fluid": "Gender Fluid",
            "Gender Neutral": "Gender Neutral",
            "Other": "O"
        }
        
        try {
            let permission = globalPermissionValidator(user_profile_permissions, userPermission)
            let profile_details = {
                gender: gender_list[data.find(ele => ele.questionId === questionIds.gender).answer],
                education: data.find(ele => ele.questionId === questionIds.education).answer,
                workExperience: data.find(ele => ele.questionId === questionIds.work_exp).answer,
                nationality: data.find(ele => ele.questionId === questionIds.nationality).answer,
            }
            permission?.profile_permissions && await editProfile({ id: getIntFromString(me?.profile?.id), ...profile_details })
        }
        catch (error) {
            console.error('Error updating profile:', error)
        }
    }

    const handleSubmit = async answers => {
        setSubmitLoading(true);
        const endTime = new Date().getTime();
        try {
            const response = await createResponse({
                answers,
                surveyId:
                    (orgSurveyById && getIntFromString(orgSurveyById.id)) ||
                    (SurveyById && getIntFromString(SurveyById.id)),
                totalDuration: prevTotalDuration + (endTime - startTime) / 1000 // returns time in seconds
            });
            response && setSubmitted(true);
            response && removeItem(orgSurveyById ? orgSurveyById.id : SurveyById.id);
           
            if (response){
                editUserProfile(answers)
                let status =localStorage.getItem('to_leraning')
                const surveyId = getIntFromString(SurveyById.id);
                removeSurveyCache(surveyId)
                if(status ==='diversity_profile'){
                    localStorage.setItem('to_leraning','diversity_profile_completed')
                    navigateRoute(mapp_learning_path_routes?.soul_learning_path)
                }
                else{
                    navigateRoute(`/survey/response/${getIntFromString(response.id)}`);
                }
                
            } 
        } catch (e) {
            throw new Error(e.message);
        }
        setSubmitLoading(false);
    };

    return (
        // <Spin spinning={submitLoading} size="large">
        <>
        {SurveyById&&SurveyById==null?<NoPermissionView content={"You Don't Have Permission To View This Survey."}/>:
        <>
        {
            showTermsAndCondition && SurveyById?.termsConditions && (
              <SurveyTermsAndCondition
                termsAndConditon={SurveyById?.termsConditions}
                accept={()=>{setshowTermsAndCondition(false)}}
                back={()=>{history.goBack()}}
              />
            )
        }
        <DiversityQuizView
            {...props}
            submitted={submitted}
            onSubmit={handleSubmit}
            setPrevTotalDuration={setPrevTotalDuration}
            setshowTermsAndCondition={setshowTermsAndCondition}
            quizStartTime={startTime}
        />
        </>}
        </>
        // </Spin>
    )
}

export default compose(withCreateResponseOptimized, withSurveyByIdNewOptimized, withEditProfile)(DiversityHome)
