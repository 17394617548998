
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Button, Col, Divider, Modal, Row, Spin, Switch, Tooltip } from 'antd';
import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Link, useParams } from 'react-router-dom';
import { IMG } from '../../../config';
import { compose } from '../../core';
import { CardLayout, EmployeeTransfer, getIntFromString, getTeamBase64StringFromInt, getVerticalBase64StringFromInt, ReactMarkdown, SelectFilterField } from '../../look';
import { TEAM_BY_TEAM_ID_QUERY, TEAM_BY_TEAM_ID_QUERY_OPTIMIZED } from '../graphql/TeamByTeamIdQuery.gql';
import { TEAMS_BY_VERTICAL_ID_QUERY, TEAMS_BY_VERTICAL_ID_QUERY_OPTUMIZED } from '../graphql/TeamsByVerticalIdQuery.gql';
import { VERTICAL_BY_VERTICAL_Id_QUERY } from '../graphql/VerticalByVerticalIdQuery.gql';
import ROUTE from '../route';
import AddVertical from './AddVertical';
import {
  withDeleteVertical, withEditOrganization,
  withOrganizationByIdOptimized, withOrganizationFilterUpdating, withOrganizationState, withVerticalsByOrgIdQueryOptimized
} from './OrganizationOperation';
import { subscribeToOrganizationById, subscribeToOrgEmployee, subscribeToVerticalByOrg, subscribeToVerticalByOrgId } from './OrganizationSubscriptions';

const OrganizationDetail = props => {
  const {
    loading,
    organizationById,
    verticals,
    editOrganization,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    onFiltersRemove,
    deleteVertical,
    onPaginationChange,
    onSubverticalChange,
    match,
    navigation,
    client,
    subscribeToMore
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [employee, setEmployee] = React.useState(false);
  const [fetchLoading, setFetchLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [member, setMember] = React.useState({ ver: false, tem: false });
  const [verticalId, setVerticalId] = React.useState('');
  const [teamId, setTeamId] = React.useState('');
  const [Treevisible, setTreeVisible] = React.useState({ step1: false, step2: false, verticalId: 0 });
  const [actionLoading, setActionLoading] = React.useState(false);
  const [verticalList, setVerticalList] = React.useState()
  const { id } = useParams();
  const [admin_list, SetAdminList] = React.useState([])

  React.useEffect(() => {
    if (organizationById) {
      let list = [
        {
          user: organizationById?.ceo?.user,
          role: "CEO",
          employee_id: organizationById?.ceo?.id
        }
      ].concat(
        organizationById?.poc?.edges?.map(({ node }) => {
          return {
            user: node?.user,
            role: "POC",
            employee_id: node?.id
          }
        })
      )
      SetAdminList(list)
    }
  }, [organizationById])

  React.useEffect(() => {
    const subscribe =
      subscribeToVerticalByOrgId(subscribeToMore /* , props.filter */)
    subscribeToOrganizationById(subscribeToMore /* , props.filter */) ||
      subscribeToVerticalByOrg(subscribeToMore /* , props.filter */) ||
      subscribeToOrgEmployee(subscribeToMore /* , props.filter */)
    return subscribe();
  });

  React.useEffect(() => {
    setVerticalList(verticals)
  }, [verticals])

  const handleEmployeeSubmit = async employees => {
    try {
      await editOrganization({
        id: getIntFromString(organizationById.id),
        name: organizationById.name,
        employees
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const verticalOperations = (data, type) => {
    let vertical_list = verticalList.edges
    vertical_list.push({ node: data })
    verticals['edges'] = vertical_list
    verticals['edgeCount'] = vertical_list.length
    setVerticalList({ ...verticals })
  }


  const handleEditOrganization = async (e, field) => {
    setActionLoading(true);
    let data = {};
    if (field === 'allowComparison') data = { allowComparison: e };
    try {
      await editOrganization({
        id: Number(getIntFromString(organizationById.id)),
        name: organizationById.name,
        ...data
      });
    } catch (e) {
      throw new Error(e.message);
    }
    setActionLoading(false);
  };

  return (
    <CardLayout
      active={ROUTE.organization}
      title={[
        {
          name: organizationById?.name,
          link: `${ROUTE.organizationDetailLink}${organizationById && getIntFromString(organizationById.id)}`
        }
      ]}
      showTitle={false}
      editLink={ROUTE.editOrganizationLink}
      addLink={ROUTE.addOrganization}
      backLink={`${ROUTE.organization}`}
      showBack={false}
      onFiltersRemove={onFiltersRemove}
      table={false}
      rowKey={organizationById && organizationById.id}
    >
      <Row>
        <Col span={24}>
          <Spin spinning={loading || actionLoading} size="large">
            <div style={{ minHeight: '100vh', }}>
              {organizationById && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <Row gutter={[24, 24]} style={{ alignItems: 'center' }}>
                        <Col>
                          <img
                            src={organizationById.logo || IMG}
                            alt=""
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: 50,
                              marginRight: 10,
                              backgroundColor: '#f3f3f3'
                            }}
                          />
                        </Col>
                        <Col>
                          <h3 style={{ marginBottom: 0 }}>{organizationById.name}</h3>
                          <h5 style={{ color: '#898A82' }}>{organizationById.email}</h5>
                        </Col>
                      </Row>
                      <br />
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Button type="primary" ghost onClick={() => setEmployee(true)}>
                            <Link>Manage Employees</Link>
                          </Button>
                        </Col>
                        <Col>
                          <Row gutter={[24, 24]}>
                            <Col>Allow Comparison</Col>
                            <Col>
                              <Switch
                                checked={organizationById.allowComparison}
                                size="small"
                                onClick={e => handleEditOrganization(e, 'allowComparison')}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <br />

                      {/* admin_list */}
                      <div className='org-admin-profile-list'>
                        {
                          admin_list?.map(i => (
                            <div className='org-detail-admin-profile-main-container'>
                              <div className='org-detail-admin-profile'>
                                <div className='admin-profile-img-container'>
                                  <img src={i?.user?.profile?.profileImage || IMG} alt="org_logo" />
                                </div>
                                <div className='org-admin-info-section'>
                                  <h4 style={{ fontWeight: "600", fontSize: "1.2em" }}>{i?.role}</h4>
                                  <h4 style={{ textTransform: "capitalize" }}>{i?.user?.firstName} {i?.user?.lastName || ""}</h4>
                                  <Tooltip title={i?.user?.email}>
                                    <h4 >{i?.user?.email}</h4>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <br />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                        {/* <Button type="link">Edit</Button> */}
                      </Row>



                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          minHeight: '140px',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{organizationById.description}</ReactMarkdown>
                      </div>
                    </Col>

                  </Row>

                  <Row>
                    <Divider style={{ width: '100%' }} />
                  </Row>

                  <Row justify="end" gutter={[24, 24]}>
                    <Col>
                      <Button type="primary" onClick={() => setVisible(true)}>
                        Add Vertical
                      </Button>
                    </Col>
                    <Col>
                      <SelectFilterField
                        placeholder="Search Vertical by"
                        onFiltersRemove={onFiltersRemove}
                        options={[
                          {
                            label: 'Name',
                            type: 'text',
                            value: onNameChange
                          },
                          {
                            label: 'Description',
                            type: 'text',
                            value: onDescriptionChange
                          },
                          // {
                          //   label: "Employees",
                          //   value: null
                          // },
                          {
                            label: 'Subvertical',
                            type: 'subvertical',
                            value: onSubverticalChange,
                            organizationId: id
                          }
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}

                  {verticalList && (
                    <Row justify="center" style={{ minHeight: '70vh' }}>
                      <Col span={1}>
                        <Button
                          type="ghost"
                          icon={<LeftOutlined />}
                          shape="circle"
                          style={{ marginTop: '4em' }}
                          onClick={() => setTotal(total - 1)}
                          disabled={total === 0}
                        />
                      </Col>
                      <Col span={22} style={{ overflow: 'hidden' }}>
                        <Spin spinning={fetchLoading} size="medium">
                          <Tree
                            label={
                              <Row>
                                <Col span={24}>
                                  <img
                                    src={organizationById.logo || IMG}
                                    alt=""
                                    style={{
                                      width: 70,
                                      height: 70,
                                      borderRadius: 50,
                                      marginBottom: 10,
                                      backgroundColor: '#f3f3f3'
                                    }}
                                  />
                                </Col>
                                <Col span={24}>{organizationById && organizationById.name}</Col>
                              </Row>
                            }
                            lineColor={'#CACACA'}
                            lineBorderRadius={'5px'}
                          >
                            {[...Array(verticalList.edgeCount > 7 ? 7 : verticalList.edgeCount).keys()].map(i => (
                              <TreeNodeComponent
                                node={verticalList.edges[total + i].node}
                                setVerticalId={setVerticalId}
                                setMember={setMember}
                                visible={Treevisible}
                                setVisible={setTreeVisible}
                                teamId={teamId}
                                setTeamId={setTeamId}
                                setFetchLoading={setFetchLoading}
                              />
                            ))}
                          </Tree>
                        </Spin>
                      </Col>
                      <Col span={1}>
                        <Button
                          type="ghost"
                          icon={<RightOutlined />}
                          shape="circle"
                          style={{ marginTop: '4em' }}
                          onClick={() => setTotal(total + 1)}
                          disabled={total + 7 >= verticalList.edgeCount}
                        />
                      </Col>
                    </Row>
                  )
                    // :<Row align='center' justify='center' style={{width:'100%'}}><Spin spinning={true}></Spin></Row>
                  }
                  {/* </div> */}
                </>
              )}
            </div>
          </Spin>
        </Col>
      </Row>

      <Modal
        destroyOnClose
        visible={employee}
        onCancel={() => setEmployee(false)}
        footer={null}
        title="Employee"
        width="25%"
        bodyStyle={{ padding: 12, minHeight: '40vh', maxHeight: '65vh', overflow: 'auto' }}
      >
        <Row>
          {organizationById && organizationById.employees && (
            <EmployeeTransfer
              orgId={getIntFromString(organizationById.id)}
              initialValues={organizationById.employees}
              handleSubmit={handleEmployeeSubmit}
            />
          )}
        </Row>
        <br />
        <Row gutter={[24, 24]} style={{ maxHeight: "75%", overflowY: "auto" }}>
          {organizationById &&
            organizationById.employees &&
            organizationById.employees.edgeCount > 0 &&
            organizationById.employees.edges.map(({ node }) => (
              <Col span={24}>
                <Row
                  justify="space-between"
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                  {`${node.user.firstName} ${node.user.lastName}`}
                </Row>
              </Col>
            ))}
        </Row>
      </Modal>

      <Modal
        destroyOnClose
        visible={member.ver}
        onCancel={() => setMember({ ver: false, tem: false })}
        footer={null}
        title="Members"
        width="25%"
        bodyStyle={{ padding: 12 }}
        centered
      >
        <div style={{ width: "100%", overflowY: 'auto', maxHeight: "60vh" }}>
          <VerticalMember setMember={setMember} verticalId={getIntFromString(verticalId)} />
        </div>
      </Modal>

      <Modal
        destroyOnClose
        visible={member.tem}
        onCancel={() => setMember({ ver: false, tem: false })}
        footer={null}
        title="Members"
        width="25%"
        bodyStyle={{ padding: 12 }}
        centered
      >
        <div style={{ width: "100%", overflowY: 'auto', maxHeight: "60vh" }}>
          <TeamMember setMember={setMember} teamId={getIntFromString(teamId)} />
        </div>
      </Modal>

      {organizationById && organizationById.id && (
        <AddVertical
          match={match}
          navigation={navigation}
          visible={visible}
          verticalResponse={e => setTimeout(() => { verticalOperations(e, 'add') }, 300)}
          setVisible={e => setVisible(e)}
          orgId={getIntFromString(organizationById.id)}
        />
      )}
    </CardLayout>
  );
};

export default compose(
  withOrganizationState,
  withOrganizationByIdOptimized,
  withVerticalsByOrgIdQueryOptimized,
  withDeleteVertical,
  withOrganizationFilterUpdating,
  withEditOrganization,
  withApollo
)(OrganizationDetail);

const TreeNodeComponent = props => {
  const { node, setVerticalId, setMember, visible, setVisible, setTeamId, setFetchLoading } = props;
  // const [visible, setVisible] = React.useState({ step1: false, step2: false });
  const [isTeam, setIsTeam] = React.useState(false);
  return (
    <TreeNode
      label={
        <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(node.id)}`}>
          <Button
            type="link"
            style={{
              boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
              margin: 5
            }}
            onMouseEnter={() => setVisible({ step1: true, step2: false, verticalId: node.id })}
          // onMouseLeave={() => setVisible(false)}
          >
            {node.name}
          </Button>
        </Link>
      }
    >
      {visible.verticalId === node.id && visible.step1 ? (
        <>
          {node.subvertical
            ? node?.subvertical?.edges?.map(({ node: sv }) => (
              <>
                <TreeNode
                  label={
                    <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(sv.id)}`}>
                      <Button
                        type="link"
                        style={{
                          boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                          margin: 5
                        }}
                        onMouseEnter={() => setVisible({ step1: true, step2: true, verticalId: node.id, isMember: sv.id })}
                      // onMouseLeave={() => setVisible(false)}
                      >
                        {sv.name}
                      </Button>
                    </Link>
                  }
                >
                  {visible.step2 && visible.isMember === sv.id ? (
                    <>
                      <TreeNode
                        label={
                          <Button
                            type="link"
                            style={{
                              boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                              margin: 5
                            }}
                            onClick={() => {
                              setVerticalId(sv.id);
                              setMember({ ver: true, tem: false });
                            }}
                            onMouseLeave={() => setVisible({ step1: false, step2: false, verticalId: node.id })}
                          >
                            Members
                          </Button>
                        }
                      ></TreeNode>

                      {isTeam ? null : (
                        <TeamNode
                          setIsTeam={setIsTeam}
                          setVisible={setVisible}
                          setTeamId={setTeamId}
                          setMember={setMember}
                          setFetchLoading={setFetchLoading}
                          verticalId={getIntFromString(sv.id)}
                        />
                      )}
                    </>
                  ) : null}
                </TreeNode>
              </>
            ))
            : null}

          {isTeam ? null : (
            <TeamNode
              setIsTeam={setIsTeam}
              setTeamId={setTeamId}
              setMember={setMember}
              setVisible={setVisible}
              verticalId={getIntFromString(node.id)}
              setFetchLoading={setFetchLoading}
            />
          )}

          <TreeNode
            label={
              <Button
                type="link"
                style={{
                  boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                  margin: 5
                }}
                onClick={() => {
                  setVerticalId(node.id);
                  setMember({ ver: true, tem: false });
                }}
                onMouseLeave={() => setVisible({ step1: false, step2: false })}
              >
                Members
              </Button>
            }
          ></TreeNode>
        </>
      ) : null}
    </TreeNode>
  );
};
const VerticalMemberComponent = props => {
  const { vertical, setTeam, loading } = props;
  return (
    <>
      <Row justify={loading ? "center" : "start"} align='middle'>
        <Spin spinning={loading}>

          {
            vertical && vertical?.employees &&
            <div>
              {
                vertical.employees.edgeCount > 0 ?
                  vertical.employees.edges.map(({ node }) => (

                    <Row justify="space-between" style={{ paddingTop: 10 }}>
                      <Col>
                        <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                        {`${node.user.firstName} ${node.user.lastName}`}
                      </Col>

                    </Row>
                  ))
                  :
                  <div style={{ position: 'relative', left: '90px' }}>No Employees Found</div>
              }

            </div>
          }


        </Spin>
      </Row>
    </>
  );
};

const VerticalMember = compose(
  graphql(VERTICAL_BY_VERTICAL_Id_QUERY, {
    options: props => {
      let id = '';
      if (props.verticalId) {
        id = getVerticalBase64StringFromInt(props.verticalId);
      }
      return { variables: { id: id } };
    },
    props({ data }) {
      const { loading, error, vertical, subscribeToMore, updateQuery } = data;
      return { loading, error, vertical, subscribeToMore, updateQuery };
    }
  })
)(VerticalMemberComponent);

const VerticalTeamNode = props => {
  const { Teams, setIsTeam, setVisible, verticalId, setTeamId, setMember } = props;
  const [isMemeber, setIsMember] = React.useState({ visible: false, id: '' });
  if (Teams && Teams.edges.length > 0) {
    setIsTeam(false);
    return Teams.edges.map(({ node }) => (
      <>
        <TreeNode
          label={
            // <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(node.subvertical.id)}`}>
            <Button
              type={'link'}
              style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
              onMouseEnter={() => setIsMember({ visible: true, id: node.id })}
            >
              {node.name}
            </Button>
            // </Link>
          }
        >
          {isMemeber.visible && isMemeber.id === node.id ? (
            <TreeNode
              label={
                <Button
                  type="link"
                  style={{
                    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                    margin: 5
                  }}
                  onClick={() => {
                    setTeamId(node.id);
                    setMember({ ver: false, tem: true });
                  }}
                  onMouseLeave={() => setVisible({ step1: false, step2: false, verticalId })}
                >
                  Members
                </Button>
              }
            ></TreeNode>
          ) : null}
        </TreeNode>
      </>
    ));
  } else {
    // setIsTeam(true);
    return null;
  }
};
const TeamNode = compose(
  graphql(TEAMS_BY_VERTICAL_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.verticalId) {
        id = getVerticalBase64StringFromInt(props.verticalId);
      }
      return { variables: { vertical: id } };
    },
    props({ data, ownProps: { setFetchLoading } }) {
      const { loading, error, Teams, subscribeToMore, updateQuery } = data;
      setFetchLoading(loading);
      return { loading, error, Teams, subscribeToMore, updateQuery };
    }
  })
)(VerticalTeamNode);

const TeamMemberComponent = props => {
  const { setTeam, Team, loading } = props;
  console.log('TeamByTeamId::', Team);
  return (
    <>
      <Spin spinning={loading}>

        {
          Team?.employees &&
            Team?.employees?.edgeCount > 0 ?

            <div>
              {
                Team?.employees?.edges.map(({ node }) => (
                  <Row justify="space-between" style={{ paddingTop: 10 }}>
                    <Col>
                      <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                      {`${node.user.firstName} ${node.user.lastName}`}
                    </Col>
                  </Row>
                ))
              }
            </div>
            :
            <div style={{ position: 'relative', left: '90px' }}>No Employees Found</div>
        }

      </Spin>

    </>
  );
};

const TeamMember = compose(
  graphql(TEAM_BY_TEAM_ID_QUERY_OPTIMIZED, {
    options: props => {
      let id = '';
      console.log('teamId::', props.teamId);
      if (props.teamId) {
        id = getTeamBase64StringFromInt(props.teamId);
      }
      return { variables: { id: id } };
    },
    props({ data }) {
      const { loading, error, Team, subscribeToMore, updateQuery } = data;
      return { loading, error, Team, subscribeToMore, updateQuery };
    }
  })
)(TeamMemberComponent);



