import React from 'react';
import _ from 'lodash';
import { Row, Col, Form, Divider } from 'antd';

import {
  TextField,
  TransferField,
  EmployeeField,
  getIntFromString,
  ButtonsComponent,
  CheckBoxField,
  CKEditorField,
  validateEmail,
  RenderUploadWithCrop
} from '../../look';

export const CKEditorFieldName = 'description';
export const CKEditorFieldName1 = 'termsConditions';

const OrganizationFormComponent = props => {
  const { organizationById, onSubmit } = props;
  const [button, setButton] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [email, setEmail] = React.useState('' || (organizationById && organizationById.email));
  const [form] = Form.useForm();

  const initialValue = {
    name: organizationById && organizationById.name,
    logo: organizationById && organizationById.logo,
    description: organizationById && organizationById.description,
    email: organizationById && organizationById.email,
    allowComparison: organizationById && organizationById.allowComparison,
    poc: organizationById && organizationById.poc && organizationById.poc?.edges?.map(({node})=>getIntFromString(node.id)),
    ceoId: organizationById && organizationById.ceo && getIntFromString(organizationById.ceo.id)
  };
  const disabledField = !validateEmail(email);

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          onFinish={values => onSubmit(values, button)}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={10} offset={1}>
              <Row>
                <Col lg={14} md={20}>
                  <TextField name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]} />
                </Col>
              </Row>

              <Row>
                <Col lg={14} md={20}>
                  <TextField
                    name="email"
                    label="E Mail"
                    rules={[
                      { required: true, message: 'E Mail is required!' },
                      {
                        required: true,
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      }
                    ]}
                    onChange={e => setEmail(e?.target?.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={14} md={20}>
                  <CheckBoxField name="allowComparison" label="Allow Comparison" />
                </Col>
              </Row>
            </Col>

            <Col lg={10} md={13} xs={24}>
              <Row>
                <Col span={24}>
                  <EmployeeField
                    name="poc"
                    label="POC"
                    rules={[{ required: true, message: 'POC is required!' }]}
                    disabled={disabledField}
                    mode="multiple"
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <EmployeeField
                    name="ceoId"
                    label="CEO"
                    rules={[{ required: true, message: 'CEO is required!' }]}
                    disabled={disabledField}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={14} md={20}>
                  <RenderUploadWithCrop
                    width={300}
                    height={250}
                    setUrl={e => form.setFieldsValue(_.set(form.getFieldsValue(true), ['logo'], e))}
                    setLoad={setLoad}
                    name={['logo']}
                    label="Image" /* rules={[{ required: true, message: 'Image is required!' }]} */
                    value={initialValue?.logo}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={0} xs={0} />
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Description"
                initialValue={organizationById && organizationById.description}
                // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName1}
                label="Terms Conditions"
                initialValue={organizationById && organizationById.termsConditions}
                // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
          </Row>
          {/* <MarkdownField
            name="description"
            label="Description"
            initialValue={organizationById && organizationById.description}
            // rules={[{ required: true, message: 'All questions are required!' }]}
          /> */}
          <Divider />
          <ButtonsComponent disabled={load} setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default OrganizationFormComponent;
