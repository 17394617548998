import React from "react";
import UserProfileEditView from "../componens/userProfileEditView";
import { compose } from "modules/core";
import { withEditProfile } from "modules/user/containers/UserOperations";
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString } from "modules/look";
import { message } from "antd";
import { withUpdateAccount } from "./userProfileOpertions";

const UserProfileContainer = (props) => {
    const { me, editProfile, onClose ,updateAccount} = props;

    const editUserProfile = async (value) => {
        try {
            let profile_details = {
                gender: value?.gender,
                education: value?.education,
                workExperience: Number(value?.experience),
                nationality: value?.nationality,
            }
            await updateAccount({firstName:value?.firstName,lastName:value?.lastName})
            let response = await editProfile({ id: getIntFromString(me?.profile?.id), ...profile_details })
            if (response?.id) {
                message.success("Profile updated successfully")
                onClose('success')
            }
        }
        catch (error) {
            console.error('Error updating profile:', error)
        }
    }
    return (
        <>
            <UserProfileEditView {...props} onSubmit={(value) => editUserProfile(value)} />
        </>
    )
}

export default compose(withApollo, withEditProfile,withUpdateAccount)(UserProfileContainer)