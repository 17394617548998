import React from "react";
import SalesPageView from "../components/SalesPageView";
import gql from "graphql-tag";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';

const NPS_QUREY = gql`
query NPS{
    NPS
}
`
const SalesPageContainer = (props) => {
    const {client} =props
    const [nps_rating,setNpsRating] = React.useState()
    React.useEffect(()=>{
        getNps()
    },[])

    const getNps = async () => {
        try {
            const { data } = await client.query({
                query: NPS_QUREY,
                fetchPolicy: 'network-only'
            })
            if(data){
                setNpsRating(data?.NPS)
            }
        }
        catch(e){

        }
    }

    return (
        <>
            <SalesPageView nps_rating={nps_rating}/>
        </>
    );
};

export default compose(withApollo)(SalesPageContainer);

