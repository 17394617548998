import { Spin } from 'antd';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { compose, getItem } from '../core';
import NotFound from '../page/containers/NotFound';
import { withMe } from '../user/containers/UserOperations';
import USER_ROUTE from '../user/route';

import { withApollo } from '@apollo/client/react/hoc';
import { ACCESS_TOKEN, question_id_for_3cs, report_tool_3cs, SURVEY_ID_3CS } from '../../config';
import { ALL_USER_PERMISSION } from 'modules/user/graphql/GetUserPermission.gql';
import PageLayout, { SURVEY_CONDITION_CHECK, THREECS_CONDITION_CHECK } from './Layout';
import { ORG_MODULE_SETTING } from 'modules/user/graphql/Organization-module-settings.gql';
import { getIntFromString, getQuestionBase64StringFromInt, getSurveyBase64StringFromInt, getTriggerFromLS, globalPermissionValidator, setTriggerToLS } from 'modules/look';
import { module_config } from 'module_config';
import { DIVERSITY_SURVEY_TOOL_ID, WHOLE_SURVEY_TOOL_ID, WHOPOSHORO_SURVEY_TOOL_ID } from '../../config';
import DIVERSITY_ROUTE from '../diversity-survey/route'
import survey_route, { three_cs } from 'modules/survey/route';
import user_manual_route, { user_manual } from 'modules/user-manual/route';
import { CANCEL_AI_TRIGGER } from 'modules/ai-tips/query/ai-tip-query.gql';
import { CancelAiTrigger } from './operations';
import MappReviewContainer from 'modules/mapp-review/containers/mappReviewContainer';
import { map_review_permissios } from 'Permissions/mappReview.permission';
const Routes = props => {
  const { component: Component, loading, me, protect,client,emailRoute,cancelRunpodtips, ...rest } = props;
  console.log("route props",props)
  try {
    const currentRoute = props.location.pathname
    if (localStorage.hasOwnProperty("routeHistory")) {
      const routeArray = localStorage.routeHistory.split(",")
      if (routeArray[routeArray.length - 1] !== currentRoute) {
        routeArray.push(currentRoute)
        localStorage.setItem("routeHistory", routeArray.join(","))
      }
    } else {
      localStorage.setItem("routeHistory", currentRoute)
    }
  } catch (error) {
    console.log("Error sending logs", error)
  }
  let history = useHistory();
  const [userPermission,setuserPermission]=React.useState([])
  const [permissionLoader,setPermissionLoader]=React.useState(true)
  const [org_modules,setorgModules]=React.useState()
  const [cancel_ai_tip_trigger,SetCancelAiTriggerTip] = React.useState(getTriggerFromLS || [])
  const [app_sidebar_expand, setAppSidebarExpand] = React.useState(JSON.parse(localStorage.getItem("app_sidebar_expand"))?.app_sidebar_expand || false)
  const route = JSON.parse(localStorage.getItem('emailRoute'))
  const [reviw_visible,setReviewVisible] = React.useState()
  const [review_permission,setReviewPermission] = React.useState()
  const [review_module,setReviewModule] = React.useState()
  React.useEffect(()=>{
    localStorage.setItem("app_sidebar_expand",JSON.stringify({app_sidebar_expand}))
  },[app_sidebar_expand])

  React.useEffect(()=>{
    if ( protect ) {
      getItem( ACCESS_TOKEN )
        .then( token => {
          if ( token == null || undefined ) {
            localStorage.setItem("redirected_from", props?.location?.pathname)
            history.push( "/intro" );
          }
        } )
    }

    if ( emailRoute ) {
      getItem( ACCESS_TOKEN )
        .then( token => {
          if ( (token == null || undefined) && !route?.resetToken?.length ) {
              history.push( "/user/login" );
          }else{
            let params;
            if(window.location?.search){
              params = new URLSearchParams(window.location.search)
           }
            const employee = params?.get('employee');
            const feedbackId = params?.get("feedback")
            let okr; 
            if(route?.okr){
              okr = route?.okr
            }else{
              okr = params?.get("okr")
            }

            let token; 
            if(route?.token){
              token = route?.token
            }else{
              token = params?.get("token")
            }

            let resetToken;
            if(route?.resetToken){
              resetToken = route?.resetToken
            }else{
              resetToken = params?.get("resetToken")
            }

            let readiness_zone;
            if(route?.readiness_zone){
              readiness_zone = route?.readiness_zone
            }else{
              readiness_zone = params?.get("readiness_zone")
            }

            let tool;
            if(route?.tool){
              tool = route?.tool
            }else{
              tool = params?.get("tool")
            }

            let idp_id;
            if(route?.idp_id){
              idp_id = route?.idp_id
            }else{
              idp_id = params?.get("idp_id")
            }

            
            const tab = params?.get("tab")

            let meetingId;
            if(route?.meeting){
              meetingId = route?.meeting
            }else{
              meetingId = params?.get("meeting")
            }

            let request
            if(route?.request){
              request = route?.request
            }else{
              request = params?.get("request")
            }

            let survey; 
            if(route?.survey){
              survey = route?.survey
            }else{
              survey = params?.get("survey")
            }
            
            let customEmailRoute  = route?.route 
            
            if(employee){
              customEmailRoute =  customEmailRoute + `/?employee=${employee}`
            }
            if(feedbackId){
              customEmailRoute = customEmailRoute + `/?feedback=${feedbackId}`
            }
            if(meetingId){
              customEmailRoute = customEmailRoute + `/${meetingId}?tab=upcoming`
            }
            if(tab){
              customEmailRoute = customEmailRoute + `&tab=${tab}`
            }
            if(okr){
              customEmailRoute = customEmailRoute + `/${okr}`
            }
            if(token){
              customEmailRoute = customEmailRoute + `/${token}`
            }
            if(resetToken){
              customEmailRoute = customEmailRoute + `/${resetToken}`
            }
            if(readiness_zone){
              customEmailRoute = customEmailRoute + `/${readiness_zone}`
            }
            if(tool){
              customEmailRoute = customEmailRoute + `/${tool}`
            }
            if(idp_id){
              customEmailRoute = customEmailRoute + `/${idp_id}`
            }
            if(request){
              customEmailRoute = customEmailRoute + `/${request}`
            }
            if(survey){
              customEmailRoute = customEmailRoute + `/${survey}`
            }
            history.push( customEmailRoute );            
           
        }
        } )
    }
     
    
  },[protect,emailRoute])

  React.useEffect(()=>{
    
    if(me){
      getUserPermission()
      let org_id = me?.employee?.organizationSet?.edges[0]?.node?.id
      getModules(getIntFromString(org_id))
    }
  },[me])

  React.useEffect(()=>{ 
    if(cancel_ai_tip_trigger?.length){
      setTriggerToLS([])
      CancelAiTip(cancel_ai_tip_trigger)
    }
  },[cancel_ai_tip_trigger])


  const CancelAiTip=async(list=[])=>{ 
      try {  
        if(list?.length){ 
          list.forEach(async(i)=>{ 
            cancelRunpodtips({jobId:i?.jobId}) 
            
          })
        }
      } catch (error) {
        
      }
  }

  const getUserPermission=async()=>{
    setPermissionLoader(true)
    const { data } = await client.query({
      query: ALL_USER_PERMISSION,
    });
    if(data?.me){
      let userPermission = Object.values(data?.me).map(item=>item?.edges).filter(edges=>edges)?.map(val=>val?.map(({node})=>node?.codename))

      // .map(({node})=>node?.codename)
      userPermission=userPermission.concat.apply([],userPermission)
      if(userPermission?.length){
        setuserPermission(userPermission)
      }else{
        setuserPermission(['**'])
      }
      
      setPermissionLoader(false)
    }
    setPermissionLoader(false)
  }

  const getModules=async(id)=>{
  try {
    const { data } = await client.query({
      query: ORG_MODULE_SETTING,
      variables:{id}
    });
    if(data?.orgSettingsById){
     
      let module_list = data?.orgSettingsById?.edges?.map(({node})=>node)
   
      let enabled_list = module_list?.filter(i=>i?.status)?.map(i=>i?.uiCfg?.name?.toUpperCase()?.replaceAll(" ","_"))
      let disabled_list = module_list?.filter(i=>!i?.status)?.map(i=>i?.uiCfg?.name?.toUpperCase()?.replaceAll(" ","_"))
      let org_module_list=[]
      let available_modules = Object.values(module_config)
      if(enabled_list?.length){
        org_module_list = enabled_list
      }
      else if(!enabled_list?.length && disabled_list?.length){
        org_module_list = available_modules?.filter(i=>!disabled_list?.includes(i))
      }
      setorgModules(org_module_list)
    }else{
      setorgModules([])
    }
  } catch (error) {
    setorgModules([])
  }
  }

  const navigateRoute = (url) => {
    if(url=="-1" || !url){
      history.goBack()
    }else{
      history.push(url)
    }
  }
  
  // if you update any part of the below function we should update the same in Layout.jsx function name is the same
  const checkSurveyStatus = async (value) => {
    if (value) {
      if(value?.type === three_cs){
        const { data } = await client.query({
          query: THREECS_CONDITION_CHECK,
          variables: {
            survey: getSurveyBase64StringFromInt(SURVEY_ID_3CS),
            question: getQuestionBase64StringFromInt(question_id_for_3cs),
            user: me?.id,
          },
          fetchPolicy: 'network-only'
        });
        
        if(data?.answers){
          let count = data?.answers?.totalCount
          if (count > 0) {
            if (value?.is_navigate) {
              navigateRoute(`${survey_route?.report3cs}`)
            } else {
              return data?.response?.responseSet
            }
          }
          else {
            if (value?.is_navigate) {
              navigateRoute(`${survey_route?.surveyQuiz3cs?.replace(":id",SURVEY_ID_3CS?.toString())}` )
            } else {
              return data?.response?.responseSet
            }
  
          }
        }
      }
      else{
        const { data } = await client.query({
          query: SURVEY_CONDITION_CHECK,
          variables: { survey: getSurveyBase64StringFromInt(value?.id) },
          fetchPolicy: 'network-only'
        });
        if (data?.response) {
          let count = data?.response?.responseSet?.totalCount
          if (value?.type === 'Diversity') {
            if (count > 0) {
              if (value?.is_navigate) {
                navigateRoute(`/tool/tool/generic/${DIVERSITY_SURVEY_TOOL_ID}`)
              } else {
                return data?.response?.responseSet
              }
            }
            else {
              if (value?.is_navigate) {
                navigateRoute(DIVERSITY_ROUTE.diversityHome.replace(':id', value?.id))
              } else {
                return data?.response?.responseSet
              }
  
            }
          }
          else if (value?.type === 'Whoposhoro') {
            if (count > 0) {
              if (value?.is_navigate) {
                navigateRoute(`/tool/tool/generic/${WHOPOSHORO_SURVEY_TOOL_ID}`)
              } else {
                return data?.response?.responseSet
              }
  
            }
            else {
              if (value?.is_navigate) {
                navigateRoute(survey_route.surveyQuiz.replace(':id', value?.id))
              } else {
                return data?.response?.responseSet
              }
  
            }
          }
          else if (value?.type === 'whole') {
            if (count > 0) {
              if (value?.is_navigate) {
                navigateRoute(`/whole/${WHOLE_SURVEY_TOOL_ID}`)
              } else {
                return data?.response?.responseSet
              }
  
            }
            else {
              if (value?.is_navigate) {
                navigateRoute(survey_route.surveyQuizWhole.replace(':id', value?.id))
              } else {
                return data?.response?.responseSet
              }
  
            }
          }
          else if(value?.type === user_manual){
            if (count > 0) {
              if (value?.is_navigate) {
                navigateRoute(user_manual_route.home?.replace(":survey_id",value?.id).replace(":response_id",getIntFromString(data?.response?.responseSet?.edges[0]?.node?.id)) )
              } else {
                return data?.response?.responseSet
              }
  
            }
            else {
              if (value?.is_navigate) {
                navigateRoute(user_manual_route.surveyQuizUserManual?.replace(":id",value?.id) )
              } else {
                return data?.response?.responseSet
              }
  
            }
          } 
        }
      }
     
    }
  }
  React.useEffect(() => {
    if(userPermission?.length){
      let permission = globalPermissionValidator(map_review_permissios, userPermission)
      setReviewPermission(permission?.required_permission)
    }
  },[userPermission])

  React.useEffect(()=>{
    let review = localStorage.getItem('review_module')
    setReviewModule(review)
  },[])

  React.useEffect(()=>{
      if(review_module && review_permission) {
        setReviewVisible(review_module)
      }
      else{
        localStorage.removeItem('review_module')
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[review_permission,review_module])


  const closeReview =()=>{
    setReviewVisible(null)
    localStorage.removeItem('review_module')
    
  }
  return (
    <Route
      key={rest.name}
      {...rest}
      render={matchProps => (
        <Spin spinning={loading} size="large">
          <PageLayout {...rest} {...matchProps} userPermission={userPermission} navigateRoute={navigateRoute} checkSurveyStatus={checkSurveyStatus} org_modules={org_modules} app_sidebar_expand={app_sidebar_expand} setAppSidebarExpand={setAppSidebarExpand}>
            {rest.admin ? (
              !loading &&
              ( me ? (
                !me.isSuperuser ? (
                  <NotFound status={'403'} navigateRoute={navigateRoute}/>
                ) : (
                  <Component {...matchProps} userPermission={userPermission} org_modules={org_modules} permissionLoader={permissionLoader} {...rest} navigateRoute={navigateRoute} me={me} checkSurveyStatus={checkSurveyStatus} app_sidebar_expand={app_sidebar_expand} setAppSidebarExpand={setAppSidebarExpand} SetCancelAiTriggerTip={SetCancelAiTriggerTip}
                  setReviewVisible={setReviewVisible} review_permission={review_permission}/>
                )
              ) : (
                typeof me !== undefined && history.push( USER_ROUTE.login )
              ) )
            ) : (
              <Component {...matchProps} userPermission={userPermission} org_modules={org_modules} permissionLoader={permissionLoader} {...rest} navigateRoute={navigateRoute} me={me} checkSurveyStatus={checkSurveyStatus} app_sidebar_expand={app_sidebar_expand} setAppSidebarExpand={setAppSidebarExpand} SetCancelAiTriggerTip={SetCancelAiTriggerTip}
              setReviewVisible={setReviewVisible} review_permission={review_permission}/>
            )}
            {reviw_visible&&<MappReviewContainer  {...matchProps} me={me} closeReview={closeReview} reviw_visible={reviw_visible} />}
          </PageLayout>
        </Spin>
      )}
    />
  );
};

export default compose(withMe,withApollo,CancelAiTrigger)( Routes );
