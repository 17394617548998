import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';

export const EDIT_ORGANIZATION = gql`
  ${ORGANIZATION_TYPE}
  mutation editOrganization(
    $ceoId: Int
    $description: String
    $logo: String
    $termsConditions: String
    $email: String
    $allowComparison: Boolean
    $employees: [Int]
    $id: ID!
    $name: String
    $poc: [Int]
  ) {
    updateOrganization(
      ceoId: $ceoId
      description: $description
      logo: $logo
      termsConditions: $termsConditions
      email: $email
      employees: $employees
      allowComparison: $allowComparison
      id: $id
      name: $name
      poc: $poc
    ) {
      organization {
        ...OrganizationType
      }
    }
  }
`;
