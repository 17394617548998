import gql from 'graphql-tag';
// import { ORGANIZATION_TYPE_CONNECTION } from './OrganizationTypeConnection.gql';

//  ${ORGANIZATION_TYPE_CONNECTION}
export const ALL_ORGANIZATION_QUERY = gql`
  query allOrganization(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $email_Icontains: String
    $ceo: ID
  ) {
    allOrganization(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      email_Icontains: $email_Icontains
      ceo: $ceo
    ) {
      # ...OrganizationTypeConnection
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          email
          logo
          poc {
            edges{
              node{
                  id
                  user {
                    id
                    username
                    firstName
                    lastName
                    email
                  }
              }
            }
          }
          ceo {
            id
            user {
              id
              username
              firstName
              lastName
              email
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;

export const ALL_ORGANIZATION_FILTER_LIST=gql`
query allOrganization($name_Icontains: String) {
  allOrganization(name_Icontains:$name_Icontains){
    edges{
      node{
        id
        name
      }
    }
  }
}
`
export const UPDATE_ORGANIZATION =gql `
mutation updateOrganization($id: ID!,$employees: [Int]){
  updateOrganization(id:$id,employees:$employees){
    organization{
        id
      }
  }
}
`

export const ADD_USER_TO_ORG =gql `
mutation createMember($memberData: MemberInput!){
  createMember(memberData:$memberData){
    member{
        id
      }
  }
}
`

export const ALL_VERTICALS=gql`
query verticals($name_Icontains: String,$organization: ID) {
  verticals(name_Icontains:$name_Icontains,organization:$organization){
    edges{
      node{
        id
        name
      }
    }
  }
}
`

export const ALL_TEAMS=gql`
query Teams($name_Icontains: String,$vertical: ID) {
  Teams(name_Icontains:$name_Icontains,vertical:$vertical){
    edges{
      node{
        id
        name
      }
    }
  }
}
`