import gql from "graphql-tag";

export const ORG_TREE_QUERY = gql`
query me{
  me{
    id
    employee {
      id
      orgPocEmployee {
        edges {
          node {
            id
            name
            email
            logo
            primaryColor
            ceo {
              id
              user {
                id
                profile {
                  id
                  profileImage
                }
                email
                firstName
                lastName
              }
            }
            poc {
              edges{
                node{
                  id
                  user {
                    id
                    profile {
                      id
                      profileImage
                    }
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            employees {
              totalCount
            }
            verticalSet {
              edges {
                node {
                  id
                  name
                  heads {
                    edges {
                      node {
                        id
                        user {
                          id
                          firstName
                          lastName
                          email
                          profile {
                            id
                            profileImage
                          }
                        }
                      }
                    }
                  }
                  teamSet {
                    edges {
                      node {
                        id
                        name
                        managers {
                          edges {
                            node {
                              id
                              user {
                                id
                                firstName
                                lastName
                                email
                                profile {
                                  id
                                  profileImage
                                }
                              }
                            }
                          }
                        }
                        employees {
                          totalCount
                        }
                        vertical {
                          id
                          name
                        }
                      }
                    }
                  }
                  parentvertical {
                    id
                  }
                  employees {
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const DELETE_VERTICAL = gql`
  mutation deleteVertical($id: ID!) {
    deleteVertical(id: $id) {
      vertical {
        id
      }
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      team {
        id
      }
    }
  }
`;

export const DELETE_POC_EMPLOYEE = gql`
  mutation deleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      employee{
        id
      }
    }
  }
`