import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const ORGANIZATION_TYPE = gql`
  ${EMPLOYEE_TYPE}
  fragment OrganizationType on OrganizationType {
    id
    name
    description
    email
    logo
    termsConditions
    allowComparison
    poc {
      edges{
        node{
          ...EmployeeType
        }
      }
    }
    ceo {
      ...EmployeeType
    }
    employees {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...EmployeeType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
