import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';
export const ORGANIZATION_BY_ID_QUERY = gql`
  ${ORGANIZATION_TYPE}
  query organizationById($id: ID!) {
    organizationById(id: $id) {
      ...OrganizationType
    }
  }
`;



export const ORGANIZATION_BY_ID_QUERY_OPTIMIZED = gql`
${EMPLOYEE_TYPE}
  query organizationById($id: ID!) {
    organizationById(id: $id) {
      id
      name
      description
      email
      logo
      termsConditions
      allowComparison
      poc {
        edges{
          node{
            id
            user {
              id
              email
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
      }
      
      ceo {
        id
        user {
          id
          email
          firstName
          lastName
          profile{
            id
            profileImage
          }
        }
      }
      employees {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            ...EmployeeType
          }
        }
        totalCount
        edgeCount
      }
    }
  }
`;


const NESTED_SUB_VERTICALS = gql`
fragment NestedSubVerticals on VerticalType{
  id
  name
  subvertical {
    edges {
      node {
        id
        name
      }
    }
  }
}

`


const SUB_VERTICALS = gql`
${NESTED_SUB_VERTICALS}
fragment subverticals on VerticalTypeConnection{
  totalCount
   edges{
    node{
     ...NestedSubVerticals
    }
  }
}


`


export const ORGANIZATION_BY_ID_QUERY_ASSIGN = gql`
${SUB_VERTICALS}
  query organizationById($id: ID!) {
    organizationById(id: $id) {
    id
    name
    description
    email
    logo
    termsConditions
    allowComparison
      verticalSet {
        edges {
          node {
            id
            name
            subvertical {
              totalCount
              ...subverticals
            }
          }
        }
      }
    }
  }
`;
