import back_arrow from '../assets/new_back_arrow.svg'
import bulb_icon from '../assets/learing-bulb-icon.svg'
import soul_baner_bg from '../assets/learn-icons/soul-learn-baner-bg.svg'
import timer from '../assets/learn-icons/learn-timer-icon.svg'
import learn_play_icon from '../assets/learn-icons/learn-play-icon.svg'
import learn_play_icon_black from '../assets/learn-icons/learn-play-icon-black.svg'
import learn_info_icon from '../assets/learn-icons/leran-info-icon.svg'
import learn_poper from '../assets/learn-icons/party-poper.svg'
import trophy_icon from '../assets/learn-icons/learn-trophy-icon.svg'
const soul_icon = "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739514622/MApp/UI/Images/learn-soul-icon_yfyxca.png"
export const learningPathIcons ={
    back_arrow,
    bulb_icon,
    soul_baner_bg,
    timer,
    soul_icon,
    learn_play_icon,
    learn_play_icon_black,
    learn_info_icon,
    learn_poper,
    trophy_icon
}