import React from "react";
import styled from "styled-components";
import { Card, Checkbox, Row, Col, Form, Input, Button } from "antd";
import { UserAddOutlined, LoginOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SignInLogo from "../../assets/Frame.svg";
import LogoImg from "../../assets/brainayan-log-new.svg";
import PAGE_ROUTE from "../../page/route";
import { MetaTags } from "../../look";
import ForgotPassword from "../containers/ForgotPassword";
import Map_logo  from '../../assets/mapp-logo.svg'
import ROUTE from "../route";
import '../css/login.css'
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const Login = ( props ) => {
  const { incorrectPass, onSubmit, msg, location, nonFieldErrors } = props;
  const form = React.useRef();
  React.useEffect( () => {
    if ( incorrectPass || ( nonFieldErrors && nonFieldErrors.length > 0 ) )
      form.current.resetFields( [ "password" ] );
  } );

  const history = useHistory();



  React.useEffect(()=>{

    const route = JSON.parse(localStorage.getItem('emailRoute'))

    if(route?.resetToken){
      localStorage.removeItem('emailRoute');
    }

  },[])

  return (
    <>
      <MetaTags title="Login" description="This is Login page" />
      <Row align="center" justify="center" className="web-login" style={{height:"100vh",padding:'0px'}}>
        <FormCol className="login-container" lg={10} md={11} sm={12} xs={24}>
          <div style={{width:"100%",paddingTop:"10px"}}>
            <img
              src={LogoImg}
              alt=""
              height={"50px"}
              style={{ marginBottom: "10px",display:'flex',alignItems:'start',justifyContent:'start',marginLeft:"-5px"}}
            />
            {msg ? (
              <div style={{ margin: "10px 0" }}>
                <h4>
                  If you have taken our MApp survey using a token,
                  <br /> please use the email ID associated with
                  that survey as your user ID <br /> to login and
                  use "Forgot Password?"
                </h4>
              </div>
            ) : (
              <>
              </>
            )}
            <Welcome>Welcome Back!</Welcome>
            <p className="login-sub-head" style={{paddingLeft:'14px',color:'#595959'}}><span style={{color:'#4CCB1F'}}>MApp -</span>  The Manager Awesomeness App </p>
          </div>
                  
              <Form
                {...layout}
                requiredMark={false}
                name="login-form"
                onFinish={onSubmit}
                ref={form}
                style={{width:"100%",marginTop:'20px'}}
                className="custom-form"
              >
                <Form.Item
                  name="email"
                  label="Username or Email"
                  validateStatus={
                    incorrectPass
                      ? "error"
                      : nonFieldErrors
                        ? "error"
                        : ""
                  }
                  help={
                    incorrectPass
                      ? "Email or password is incorrect!!"
                      : nonFieldErrors
                        ? nonFieldErrors.length > 0 &&
                        nonFieldErrors[ 0 ].message
                        : null
                  }
                  rules={[
                    {
                      required: true,
                      // message: "Email/username is required",
                      message: <div style={{position:'absolute',fontSize:'12px',right:'27px'}}><span style={{color:'#ff4d4f'}}>*</span>Required</div>,
                    },
                  ]}
                >
                  <Input autoComplete='off'/>
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      // message: "Enter password to login",
                      message: <div style={{position:'absolute',fontSize:'12px',right:'27px'}}><span style={{color:'#ff4d4f'}}>*</span>Required</div>,
                      // string: {
                      //   min: 'Password must be as least 8 characters'
                      // }
                    },
                    // {
                    //   min: 8,
                    //   message: 'Password must be as least 8 characters'
                    // }
                  ]}
                  // hasFeedback
                // extra={
                //   <a className="login-form-forgot" href="">
                //     Forgot password
                //   </a>
                // }
                >
                  <Input.Password autoComplete='off'/>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox style={{marginLeft:"10px"}}>Remember Me</Checkbox>
                  </Form.Item>
                  <ForgotPassword location={location} />
                </Form.Item>
                <div style={{display:"flex",justifyContent:'center',alignItems:"center",marginLeft:'10px'}}>
                    <Button block type="primary" htmlType="submit" className="login-btn">
                      Login
                    </Button>
                </div>
              </Form>
              {/* <br />
              <Button block type="default">
                <GoogleOutlined />
                Login with Google
              </Button> */}
              <Row>
                <Col lg={0} md={0} sm={24} xs={24}>
                  <br />
                  <span style={{ color: "grey" }}>
                    Don’t have account?
                  </span>
                  &nbsp;<a href={ROUTE.register}>Sign up</a>
                </Col>
              </Row>
              <Row justify="center">
              <div className="poppins" style={{fontSize:'18px',fontWeight:'700',marginTop:'10px'}}>Don't have account ? <span onClick={(e)=>history.push("/intro/getintouch")} style={{color:'#237AFF',cursor:'pointer'}}> Get in touch.</span> </div>
              </Row>
              
              <br />
              <br />
              <div style={{width:'100%',justifyContent:'start',display:'flex',alignItems:'flex-start',paddingBottom:"20px"}}>
              <PrivacyLink to={PAGE_ROUTE.termsAndCondition} target='_blank'>
                Privacy policy and Terms of Services
              </PrivacyLink>
              </div>
         
        </FormCol>
        <Col className="image-container-bg" lg={14} md={13} sm={12} xs={0} style={{display:"flex",flexDirection:"column",justifyContent:'center',alignItems:'center'}}>
          <Link to={PAGE_ROUTE.faqPage} target='_blank' className="faq-title">FAQs</Link>
              <img className="login-logo" src={SignInLogo} alt="" style={{marginBottom:"150px"}}/>
              {/* <img className="login-bg-bottom" src={Login_Bg} alt="" /> */}
        </Col>
      </Row>
      <Col className="form-mobile-screen" style={{minHeight:"100vh",display:"flex",flexDirection:"column",justifyContent:'center',alignItems:"center"}}>
          <FormCol className="login-container"span={24} style={{height:"100%"}}>
          <img className="mobile-logo" style={{marginBottom:"40px",marginTop:"20px"}} src={Map_logo} alt="" />
            {msg ? (
              <div style={{ margin: "10px 0" }}>
                <h4>
                  If you have taken our MApp survey using a token,
                  <br /> please use the email ID associated with
                  that survey as your user ID <br /> to login and
                  use "Forgot Password?"
                </h4>
              </div>
            ) : (
              <>
              </>
            )}
            <Welcome>Welcome Back!</Welcome>
           
            <p className="login-sub-head" style={{color:'#595959'}}><span style={{color:'#4CCB1F'}}>MApp -</span>  The Manager Awesomeness App </p>
              <Form
                {...layout}
                requiredMark={false}
                name="login-form"
                onFinish={onSubmit}
                ref={form}
                style={{width:"100%",marginTop:'20px'}}
                className="custom-form"
              >
                <Form.Item
                  name="email"
                  label="Username or Email"
                  validateStatus={
                    incorrectPass
                      ? "error"
                      : nonFieldErrors
                        ? "error"
                        : ""
                  }
                  help={
                    incorrectPass
                      ? "Email or password is incorrect!!"
                      : nonFieldErrors
                        ? nonFieldErrors.length > 0 &&
                        nonFieldErrors[ 0 ].message
                        : null
                  }
                  rules={[
                    {
                      required: true,
                      message: <div style={{position:'absolute',fontSize:'12px',right:'27px'}}><span style={{color:'#ff4d4f'}}>*</span>Required</div>,
                    },
                  ]}
                >
                  <Input autoComplete='off'/>
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: <div style={{position:'absolute',fontSize:'12px',right:'27px'}}><span style={{color:'#ff4d4f'}}>*</span>Required</div>,
                      // string: {
                      //   min: 'Password must be as least 8 characters'
                      // }
                    },
                    // {
                    //   min: 8,
                    //   message: 'Password must be as least 8 characters'
                    // }
                  ]}
                  // hasFeedback
                // extra={
                //   <a className="login-form-forgot" href="">
                //     Forgot password
                //   </a>
                // }
                >
                  <Input.Password autoComplete='off'/>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox>Remember Me</Checkbox>
                  </Form.Item>
                  <ForgotPassword location={location} />
                </Form.Item>
                <div style={{display:"flex",justifyContent:'center',alignItems:"center",marginLeft:'10px'}}>
                    <Button block type="primary" htmlType="submit" className="login-btn">
                      Login
                    </Button>
                </div>
              </Form>
              {/* <br />
              <Button block type="default">
                <GoogleOutlined />
                Login with Google
              </Button> */}
              <Row>
                {/* <Col lg={0} md={0} sm={24} xs={24}>
                  <br />
                  <span style={{ color: "grey" }}>
                    Don’t have account?
                  </span>
                  &nbsp;<a href={ROUTE.register}>Sign up</a>
                </Col> */}
              </Row>
              <Row justify="center">
              <div className="poppins" style={{fontSize:'16px',fontWeight:'700',marginTop:'15px'}}>Don't have account ? <span onClick={(e)=>history.push("/intro/getintouch")} style={{color:'#237AFF',cursor:'pointer'}}> Get in touch.</span> </div>
              </Row>
              <br/>
              <div style={{width:'100%',justifyContent:'start',display:'flex',alignItems:'flex-start',paddingBottom:"20px"}}>
              <PrivacyLink to={PAGE_ROUTE.termsAndCondition} target='_blank'>
                Privacy policy and Terms of Services
              </PrivacyLink>
              </div>
             
         
        </FormCol>
      </Col>
    </>
  );
};

export default Login;

// const SingUpBtn = styled(Button)`
//   background-color: #fff;
//   color: #4e0080;
// `;

const FormCol = styled( Col )`
`;

const PrivacyLink = styled( Link )`
  text-decoration: underline;
  color: #BFBFBF;
  margin-left:14px;
`;

const Logo = styled.h3`
  font-weight: bold;
`;

const Welcome = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  /* identical to box height */
  letter-spacing: -0.03em;
  color: #000000;
  margin:0;
  padding-left:14px
`;

const SignUpTitle = styled.h1`
  color: white;
  margin-bottom: 0;
  /* font-size: 35px; */
  font-weight: bold;
`;

const SignUpContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 65%;
  color: white;
  margin: 50px;
  bottom: 80px;
`;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

