import { Modal, Spin } from "antd";
import React from "react";
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
import { learningConfig } from "../configs/learning-config";

const CongratulationPopupView =(props)=>{
    const {show_view,onClose,survey_statuses,page_loading}=props
    const [completed_count,setCompletedCount]=React.useState()
    React.useEffect(()=>{
        if(survey_statuses){
            let countTotalOne = 0;
            Object.entries(survey_statuses).forEach(([key, value]) => {
                if (value.totalCount > 0 && key !== "diversity_profile") {
                    countTotalOne++;
                }
            })
            setCompletedCount(countTotalOne)
        }
    },[survey_statuses])
    
    return (
        <Modal
        visible={show_view}
        footer={false}
        closable={false}
        centered={true}
        destroyOnClose={true}
        className="congrats-modal">
            <Spin spinning={page_loading}>
            <div style={{width:'100%',minHeight:"10em"}}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '100%',padding:'1em'}}>
                        <div></div>
                        <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() => onClose()} style={{ cursor: "pointer",marginTop:"1em",marginRight:"1em" }} />
                    </div>
                <div style={{display:'flex',flexDirection:"column",justifyContent:'center',alignItems:'center',gap:'1em',padding:"1em 3em 3em 3em"}}>
                    <h2 className="landing-card-title" style={{fontWeight:700,fontSize:"2.5em",textAlign:"center"}}>Congratulations!!</h2>
                    <h4 className="landing-card-title" style={{fontWeight:500,textAlign:"center"}}>You are {completed_count && learningConfig?.soul_card_detail?.length - 1 - completed_count} step closer to your Impact Narrative!</h4>
                </div>
            </div>
            </Spin>
        </Modal>
    )
}

export default CongratulationPopupView;