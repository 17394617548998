import React from 'react'
import KudosHomePageView from '../component/kudosHomePageView'
import { compose } from 'modules/core'
import { withApollo } from '@apollo/client/react/hoc';
import { AWARDED_BADGE_LIST, ENGAGEMENT_BOARD_QUERY, KUDOS_BADGE_COMMENT_LIST_QUERY } from '../graphql/kudosBadgeQuerys.gql';
import { Capitalize, getIntFromString, globalPermissionValidator } from 'modules/look';
import { BADGE_AWARD_SUBSCRIPTION } from '../graphql/badgeAwardSubscription.gql';
import { withCreateBadgeComment, withCreateBadgeCommentReaction, withCreateBadgeReaction, withUpdateBadgeReaction } from './badgeAwardOperation';
import { message } from 'antd';
import moment from 'moment-timezone';
import { kudos_permission } from 'Permissions/kudos.permission';
import NoPermissionView from '../component/NoPermissionComponent';
const KudosHomeContainer = (props) => {
    const { me, client,createBadgeAwardComment,createBadgeAwardReaction,createBadgeAwardCommentReaction,userPermission,updateBadgeAwardReaction } = props;
    const [new_awarder_badge_list, setAwardedBadgeList] = React.useState([]);
    const [award_badge_loading, setAwardedBadgeLoading] = React.useState(true);
    const [engagement_board_data, setEngagementBoardData] = React.useState();
    const [engagement_loading, setEngagmentLoading] = React.useState(false);
    const [issuer_page_info, SetIssuerPageInfo] = React.useState();
    const [badge_page_info, SetBadgePageInfo] = React.useState();
    const [receipient_page_info, SetReceipientPageInfo] = React.useState();
    const [badge_comment_list,setBadgeCommentList] = React.useState();
    const [badge_comment_loading,setBadgeCommentLoading] = React.useState(true)
    const [reset_input,setResetInput]=React.useState(false)
    const badge_list_ref = React.useRef([])
    const [ permission, setpermission ] = React.useState( null )
    const [permission_loading,setPermissionLoading]=React.useState(true)
    const m = React.useRef(true)

    let badgeAwardSub = undefined

    React.useEffect( () => {
        if ( userPermission?.length ) {
            setPermissionLoading(true)
            let permission = globalPermissionValidator( kudos_permission, userPermission )
            console.log("kudos_permission",permission)
            if ( permission?.can_award_badge ) {
                // getAwardBadgesList()
                getUserBadges()
            }
            setpermission( permission )
            setPermissionLoading(false)
        }
        else {
            setpermission( null )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userPermission ] )

    React.useEffect(() => {
        return () => {
            if (badgeAwardSub) {
                badgeAwardSub.unsubscribe();
            }
        }
    })

    React.useEffect(() => {
        return () => {
            m.current = false
        }
    }, [])

    React.useEffect(() => {
        m.current = true

    }, [])

    React.useEffect(() => {
        if (new_awarder_badge_list?.length) {
            badge_list_ref.current = new_awarder_badge_list
        }
    }, [new_awarder_badge_list])
    const getAwardBadgesList = async (filter) => {
        setAwardedBadgeLoading(true)

        const { data } = await client.query({
            query: AWARDED_BADGE_LIST,
            variables: { ...filter },
            fetchPolicy: "network-only"
        });
        if (data) {
            SetIssuerPageInfo(data?.issuer?.pageInfo)
            SetBadgePageInfo(data?.badge?.pageInfo)
            SetReceipientPageInfo(data?.recipient?.pageInfo)
            const dataset = [
                ...(data?.issuer?.edges || []),
                ...(data?.recipient?.edges || []),
                ...(data?.badge?.edges || [])
            ];
            var setObj = new Set();
            var unique_data_set = dataset?.reduce((acc, item) => {
                if (!setObj.has(item?.node?.id)) {
                    setObj.add(item?.node?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            let dataList = unique_data_set?.map(({ node }) =>dataProcessing(node))
            if (filter?.issuer_cursor || filter?.badge_cursor || filter?.receipient_cursor) {
                dataList = badge_list_ref.current.concat([...dataList])
            }
            setAwardedBadgeList(dataList)
            getTopUsers(dataList)
            badgeAwardSub = client.subscribe({
                query: BADGE_AWARD_SUBSCRIPTION,
                variables: { ...filter },
                fetchPolicy: "network-only"
            }).subscribe({
                next(result) {
                    let receivedBadgeCacheData = dataList
                    switch (result.data.badgeAwardSubscription.mutation) {
                        case 'CREATE':
                            const updated = receivedBadgeCacheData.concat(dataProcessing(result.data.badgeAwardSubscription.badgeAward))
                            if (m.current) {
                                setAwardedBadgeList(updated)
                            }
                            break
                        case 'UPDATE':
                            if (m.current) {
                                const updateRsponse = receivedBadgeCacheData.map(item =>
                                    item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                                        ? dataProcessing(result.data.badgeAwardSubscription.badgeAward)
                                        : item
                                )
                                setAwardedBadgeList(updateRsponse)
                            }
                            break
                        case 'DELETE':
                            if (m.current) {
                                const deleteData = receivedBadgeCacheData.filter(item =>
                                    item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                                        ? false
                                        : true
                                )
                                setAwardedBadgeList(deleteData)
                            }
                            break
                        default:
                            break
                    }
                }
            })
            setAwardedBadgeLoading(false)
        }
    }

    const dataProcessing = (data) => {
        let datalist = {
            id: data?.id,
            recipient: {
                id: data?.recipient?.id,
                user_id: data?.recipient?.user?.id,
                firstName: Capitalize(data?.recipient?.user?.firstName),
                lastName: Capitalize(data?.recipient?.user?.lastName),
                role: data?.recipient?.memberSet?.edges[0]?.node?.role?.title,
                profile: data?.recipient?.user?.profile
            },
            issuer: {
                id: data?.issuer?.id,
                user_id: data?.issuer?.user?.id,
                firstName: Capitalize(data?.issuer?.user?.firstName),
                lastName: Capitalize(data?.issuer?.user?.lastName),
            },
            badge: data?.badge,
            rational: data?.evidence,
            createdAt:data?.createdAt,
            reactions:data?.reactions
        }
        return datalist
    }
    const getUserBadges = async (filter) => {
        setEngagmentLoading(true)
        const { data } = await client.query({
            query: ENGAGEMENT_BOARD_QUERY,
            variables: { ...filter },
            fetchPolicy: "network-only"
        });
        if (data?.badgeAwards) {
            let data_set = data?.badgeAwards?.edges?.map(({ node }) => {
                return {
                    recipient: {
                        id: node?.recipient?.id,
                        user_id: node?.recipient?.user?.id,
                        firstName: Capitalize(node?.recipient?.user?.firstName),
                        lastName: Capitalize(node?.recipient?.user?.lastName),
                        role: node?.recipient?.memberSet?.edges[0]?.node?.role?.title,
                        profile: node?.recipient?.user?.profile
                    },
                    issuer: {
                        id: node?.issuer?.id,
                        user_id: node?.issuer?.user?.id,
                        firstName: Capitalize(node?.issuer?.user?.firstName),
                        lastName: Capitalize(node?.issuer?.user?.lastName),
                        role: node?.issuer?.memberSet?.edges[0]?.node?.role?.title,
                        profile: node?.issuer?.user?.profile
                    },
                }
            })
            let reciversList = getTopUsers(data_set?.map(item => item?.recipient))
            let issuesList = getTopUsers(data_set?.map(item => item?.issuer))
            setEngagementBoardData({ top_recievers: reciversList, top_issues: issuesList })
            setEngagmentLoading(false)
        }
    }
    const getTopUsers = (data) => {
        
        if (data) {

            const userCount = data.reduce((acc, item) => {
                acc[item.user_id] = (acc[item.user_id] || 0) + 1;
                return acc;
            }, {});
            const sortedUsers = Object.entries(userCount)
                .map(([user_id, count]) => ({
                    user_id,
                    count,
                    details: data.find((user) => user.user_id === user_id),
                }))
                .sort((a, b) => b.count - a.count);

            return sortedUsers.slice(0, 3);
        }
    }

    const getBadgeComments = async (filter) =>{
        setBadgeCommentLoading(true)
        const { data } = await client.query({
            query: KUDOS_BADGE_COMMENT_LIST_QUERY,
            variables: { ...filter },
            fetchPolicy: "network-only"
        });
        if(data?.badgeAward){
            let comment_list = data?.badgeAward?.badgeawardcommentSet?.edges
            ?.filter(edge => !edge?.node?.parent?.id)
            ?.map(edge => commentDataParser(edge.node)) || [];
            comment_list = comment_list?.sort((a, b) =>
                new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
            );
            setBadgeCommentList(comment_list)
            setBadgeCommentLoading(false)
        }
    }
    const commentDataParser =(comment)=>{
        return {
            id:comment?.id,
            createdAt:comment?.createdAt,
            content:comment?.content,
            reaction:comment?.reactions,
            user:{
                emp_id:comment?.employee?.id,
                user_detail:comment?.employee?.user
            },
            replies:comment?.replies?.edges?.map(({node})=>node)
        }
    }

    // Create badge comment and comment replies mutation call

    const CreateBadgeComment =async (data)=>{
        try{
        let badgeAwardCommentData={
            badgeId:getIntFromString(data?.badgeId),
            employeeId:getIntFromString(data?.employeeId),
            content:data?.content,
            ...(data?.parentId ? { parentId: getIntFromString(data?.parentId) } : {}),
        }
        let response =await createBadgeAwardComment({badgeAwardCommentData})
        if(response?.id){
            message.success("Comment sent successfully")
            setResetInput(true)
            if(data?.parentId){
                let replay_content ={
                    id:response?.id,
                    createdAt:moment()?.utc(),
                    content:data?.content,
                    reaction:[],
                    employee:{
                        id:data?.employeeId,
                        user:me?.employee?.user
                      }
                }
                setBadgeCommentList((prevList) => {
                    return prevList.map((item) => {
                        if (item?.id === data?.parentId) {
                            return {
                                ...item,
                                replies: [
                                    { ...replay_content },
                                    ...item?.replies,
                                ],
                            };
                        }
                        return item;
                    });
                });
            }
            else{
               let new_comment= {
                    id:response?.id,
                    createdAt:moment()?.utc(),
                    content:data?.content,
                    reaction:[],
                    user:{
                        emp_id:data?.employeeId,
                        user_detail:me?.employee?.user
                    },
                    replies:[]
                }
                setBadgeCommentList((prevList) => {
                    return [new_comment, ...prevList];
                });
            }
            return response
        }
        else{
            message.error('Faild to sent comment')
        }
        }
        catch(err){
            message.error('Faild to sent comment')
        }
    }

    // create badge reaction mutation call

    const createBadgeReaction =async (value) =>{
        try {
            let baReactionInput ={
                badgeAwardId: value?.id,
                employeeId: getIntFromString(value?.employeeId),
                reactionType:value?.type
            }
            let response = await createBadgeAwardReaction({baReactionInput})
            if(response?.id){
                message.success("Reaction Added Successfully")
                setAwardedBadgeList((prevList) => {
                    return prevList.map((item) => {
                        if (item?.id === value?.id) {
                            const updatedReactions = {
                                ...item.reactions,
                                edges: [...item.reactions.edges, {
                                    node: {
                                        id: response?.id,
                                        reaction: value?.type?.toUpperCase(),
                                        employee: {
                                            id: value?.employeeId,
                                        },
                                    },
                                }],
                                totalCount: item.reactions.totalCount + 1,
                            };
                            return {
                                ...item,
                                reactions: updatedReactions,
                            };
                        }
                        return item;
                    });
                });
                
            }
            else{
                message.error('could not perform this action')
            }
        }
        catch(error){
            message.error('could not perform this action')
        }
        
    }

    // create badge reaction mution call

    const createBadgeCommentReaction = async (value) =>{
        try {
            let baCommentReactionInput = {
                commentId:getIntFromString(value?.id),
                employeeId: getIntFromString(value?.employeeId),
                reactionType:value?.type
            }
            let response = await createBadgeAwardCommentReaction({baCommentReactionInput})
            if(response?.id){
                message.success("Reaction Added Successfully")
                setBadgeCommentList((prevList) => {
                    return prevList.map((item) => {
                        if (item?.id === value?.id) {
                            const updatedReactions = {
                                ...item.reaction,
                                edges:Array.isArray(item?.reaction?.edges)? [
                                    ...item.reaction.edges,
                                    {
                                        node: {
                                            id: response?.id,
                                            reaction: value?.type?.toUpperCase(),
                                            employee: {
                                                id: value?.employeeId,
                                            },
                                        },
                                    },
                                ]
                                
                              : [
                                    {
                                        node: {
                                            id: response?.id,
                                            reaction: value?.type?.toUpperCase(),
                                            employee: {
                                                id: value?.employeeId,
                                            },
                                        },
                                    },
                                ],
                                totalCount: item.reaction.totalCount? item?.reaction?.totalCount+ 1:1,
                            };
                            return {
                                ...item,
                                reaction: updatedReactions,
                            };
                        }
                        if (item.replies) {
                            item.replies = item?.replies?.map((reply) => {
                                if (reply.id === value?.id) {
                                    const updatedReplyReactions = {
                                        ...reply.reactions,
                                        edges: [
                                            ...reply?.reactions?.edges,
                                            {
                                                node: {
                                                    id: response?.id,
                                                    reaction: value?.type?.toUpperCase(),
                                                    employee: {
                                                        id: value?.employeeId,
                                                    },
                                                },
                                            },
                                        ],
                                        totalCount: reply?.reactions?.totalCount?reply?.reactions?.totalCount + 1:1,
                                    };
                                    return {
                                        ...reply,
                                        reactions: updatedReplyReactions,
                                    };
                                }
                                return reply;
                            });
                        }
                
                        return item;
                    });
                });
                
            }
            else{
                message.error('could not perform this action')
            }
        }
        catch(error){
            message.error('could not perform this action')
        }
        
    }
    const updateBadgeReaction =async (value) => {
        try {
            let response = await updateBadgeAwardReaction({id:value?.id, reaction:value?.reaction})
            if(response?.id){
                message.success("Reaction Added Successfully")
                setAwardedBadgeList((prevList) =>
                    prevList.map((item) => {
                        if (item?.id === value?.badge_id) {
                            // Check if the reaction already exists
                            const existingEdgeIndex = item.reactions.edges.findIndex(
                                (edge) => edge.node.id === value.id
                            );
                
                            if (existingEdgeIndex !== -1) {
                                // Update the existing reaction
                                const updatedEdges = item.reactions.edges.map((edge, index) => {
                                    if (index === existingEdgeIndex) {
                                        return {
                                            ...edge,
                                            node: {
                                                ...edge.node,
                                                id: response?.id,
                                                reaction: value?.reaction?.toUpperCase(),
                                                employee: {
                                                    id: me?.employee?.id,
                                                },
                                            },
                                        };
                                    }
                                    return edge;
                                });
                
                                return {
                                    ...item,
                                    reactions: {
                                        ...item.reactions,
                                        edges: updatedEdges,
                                    },
                                };
                            } else {
                                // Add a new reaction
                                const updatedReactions = {
                                    ...item.reactions,
                                    edges: [
                                        ...item.reactions.edges,
                                        {
                                            node: {
                                                id: response?.id,
                                                reaction: value?.type?.toUpperCase(),
                                                employee: {
                                                    id: value?.employeeId,
                                                },
                                            },
                                        },
                                    ],
                                    totalCount: item.reactions.totalCount + 1,
                                };
                
                                return {
                                    ...item,
                                    reactions: updatedReactions,
                                };
                            }
                        }
                        return item;
                    })
                );
                
                
            }
            else{
                message.error('could not perform this action')
            }
        }
        catch(e){
            
        }
    }
    return (
        <>
            {permission && permission?.requried_permission?<KudosHomePageView
                new_awarder_badge_list={new_awarder_badge_list}
                award_badge_loading={award_badge_loading}
                getAwardBadgesList={(filter) => getAwardBadgesList({ ...filter })}
                getUserBadges={(filter) => getUserBadges({ ...filter })}
                onPagination={(filter) => getAwardBadgesList({ ...filter })}
                engagement_board_data={engagement_board_data}
                engagement_loading={engagement_loading}
                issuer_page_info={issuer_page_info}
                badge_page_info={badge_page_info}
                receipient_page_info={receipient_page_info}
                getBadgeComments={(filter)=>getBadgeComments({...filter})}
                badge_comment_list={badge_comment_list}
                badge_comment_loading={badge_comment_loading}
                CreateBadgeComment={(data)=>CreateBadgeComment(data)}
                createBadgeReaction={(data)=>createBadgeReaction(data)}
                createBadgeCommentReaction={(data)=>createBadgeCommentReaction(data)}
                reset_input={reset_input}
                permission={permission}
                updateBadgeReaction={(value)=>updateBadgeReaction(value)}
                {...props} />:
                (!permission_loading&&<NoPermissionView />)
            }
        </>
    )
}

export default compose(withApollo,withCreateBadgeComment,withCreateBadgeReaction,withCreateBadgeCommentReaction,withUpdateBadgeReaction)(KudosHomeContainer)
